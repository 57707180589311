import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format, validate } from 'library/util';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import successImage from 'images/icon/success.png';
import wepayImage from 'images/icon/wepay.png';
import alipayImage from 'images/icon/alipay.png';
import { Actions } from 'reduxs';
import Services from 'services';
import * as qiniu from 'qiniu-js';
import { v1 } from 'uuid';
let timer
export const Detail = () => {
    const { id } = useParams();
    const [orderInfo, setOrderInfo] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);

    useEffect(() => {
        getOrderDetail();
    }, []);

    useEffect(() => {
        if (orderStatus < 5) {
            timer = setInterval(() => {
                getOrderStatus(orderStatus)
            }, 5000);
        } else {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        }
    }, [orderStatus]);

    const getOrderDetail = async () => {
        const result = await Services.order.detail({ orderId: id });
        if (result) {
            setOrderInfo(result.data);
            setOrderStatus(result.data.order.status);
        }
    }

    const getOrderStatus = async (orderStatus) => {
        const result = await Services.order.status({ orderId: id });
        if (result) {
            if (orderStatus !== (result.data && result.data.status)) {
                getOrderDetail();
            }
        }
    }

    if (orderInfo) {
        if (orderInfo.order.status === 1) {
            return <div className={styles.orderConfirm}>
                <div className={styles.successImage}>
                    <img src={successImage} alt='成功' />
                </div>
                <h1>订单已生成！</h1>
                <h1>订单号：{orderInfo.order.order_no}</h1>
                <h2>{orderInfo.product.title}</h2>

                <p>
                    <i className='icon iconfont icon-xuzhixiao'></i>
                    我们将与您确认订单信息、最终费用并签订旅游合同。<br />
                    您也可以至<a href="/center/order">我的订单</a>中，查看订单进度并尽快完善出游人员信息，感谢您的耐心等待。<br />您也可以与我们联系，金中旅随时为您服务。
                </p>
            </div>;
        } else if (orderInfo.order.status === 2) {
            return <FinishOrderInfo getOrderDetail={getOrderDetail} orderInfo={orderInfo} />
        } else if (orderInfo.order.status === 3) {
            return <div className={styles.orderConfirm}>
                <div className={styles.successImage}>
                    <img src={successImage} alt='成功' />
                </div>
                <h1>请前往12301签订旅游合同</h1>
                <h1>订单号：{orderInfo.order.order_no}</h1>
                <h2>{orderInfo.product.title}</h2>
            </div>;
        } else if (orderInfo.order.status === 4) {
            return <Pay getOrderDetail={getOrderDetail} orderInfo={orderInfo} />;
        } else {
            return <OrderInfo getOrderDetail={getOrderDetail} orderInfo={orderInfo} />;
        }
    } else {
        return false;
    }
    return false;
};


const OrderInfo = (props) => {
    const { orderInfo, getOrderDetail } = props;
    const [isNeededInvoice, setIsNeededInvoice] = useState(false);
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [sendInvoiceType, setSendInvoiceType] = useState(1);
    const [contactAddress, setContactAddress] = useState('');
    const [isPublish, setIsPublish] = useState(false);




    const audits = orderInfo.order.OrderTravellers.filter(e => moment().diff(moment(e.birthday), 'year') >= 12);
    const children = orderInfo.order.OrderTravellers.filter(e => moment().diff(moment(e.birthday), 'year') < 12);

    const price = orderInfo.product.ProductPrices && orderInfo.product.ProductPrices[0];

    return < div className={styles.container} >
        <section>
            <h1>订单信息！</h1>
            <h1>出行线路：<Link to={'/weixin/product/'+orderInfo.product.id}>{orderInfo.product.title}</Link></h1>
            <h1>订 单 号：{orderInfo.order.order_no}</h1>
        </section>
        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    旅客信息 <small>{orderInfo.audit}成人/{orderInfo.children}儿童</small>
                </div>
            </div>
            {audits.concat(children).map((item, key) => {
                return <div key={key} className={styles.line}>
                    <label>{item.name} {moment().diff(moment(item.birthday), 'year') < 12 ? '(儿童)' : ''}</label>
                    <div className={styles.right}>
                        <span>{item.sex > 0 ? '男' : '女'} {format.idCard(item.idcard_no)}</span>
                    </div>
                </div>;
            })}
        </section>
        {orderInfo.order.OrderInsurances.length > 0 ? <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    选择保险
                </div>
                <div className={styles.right}><i className='icon iconfont icon-xuzhixiao'></i>购买时请选择对应的保险产品</div>
            </div>
            {orderInfo.order.OrderInsurances.map((item, key) => {
                return <div key={key} className={`${styles.line} ${styles.insuranceWrap}`}>
                    <div className={styles.insuranceRight}>
                        <span>{item.name} <em>¥{item.price}</em>
                            <i className='icon iconfont icon-yuyinjiantou'></i>
                        </span>
                        <p>根据文旅局明文要求，游客需要购买相应的旅游意外保险，给自己一份保障，给家人一份安心。</p>
                    </div>
                </div>
            })}
        </section> : false}

        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    发票信息
                </div>
            </div>
            <div className={styles.line}>
                <label>是否开票</label>
                <div className={styles.ticketWrap}>
                    <div className={styles.control}>
                        <label><input checked={isNeededInvoice} type='radio' name='isNeededInvoice' /> 是</label>
                    </div><div className={styles.control}>
                        <label><input checked={!isNeededInvoice} type='radio' name='isNeededInvoice' /> 否</label>
                    </div>
                </div>
            </div>
            {isNeededInvoice ? <>
                <div className={styles.line}>
                    <label>发票抬头</label>
                    <div className={styles.right}>
                        <div className={styles.control}>
                            <input value={invoiceTitle} type='text' placeholder='请输入发票抬头' />
                        </div>
                    </div>
                </div>
                <div className={styles.line}>
                    <label>取票方式</label>
                    <div className={styles.ticketWrap}>
                        <div className={styles.control}>
                            <label><input checked={sendInvoiceType === 1} type='radio' /> 发票邮寄 <small> 邮寄需加收15元快递费</small></label>
                        </div>
                        <div className={styles.control}>
                            <label><input checked={sendInvoiceType === 2} type='radio' /> 上门自取 <small> 上海松江区蔡家帮路18号8433室</small></label>
                        </div>
                    </div>
                </div>
                {sendInvoiceType === 1 ? <div className={styles.line}>
                    <label>收件地址</label>
                    <div className={styles.right}>
                        <input value={contactAddress} type='text' placeholder='请填写发票收件地址' />

                    </div>
                </div> : false}

            </> : false}

        </section>
        {orderInfo.order.ProductComment ? <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    我的评论
                </div>
            </div>
            <div className={styles.rate}>
                <div className={styles.lineWrap}>
                    <label>{orderInfo.order.ProductComment.content}</label>
                </div>
                <div className={styles.media}>
                    {orderInfo.order.ProductComment.product_comment_attaches.map((item, key) => {
                        return <div key={key} className={styles.imageWrap}>
                            <img src={item.wap_url} alt='图片' />

                        </div>;
                    })}
                </div>
                <div className={styles.lineWrap}>
                    <label>行程安排：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} className={`icon iconfont icon-shoucang ${key < orderInfo.order.ProductComment.journey_score ? styles.active : ''}`}></i>
                    })}
                </div>
                <div className={styles.lineWrap}>
                    <label>饮食住宿：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} className={`icon iconfont icon-shoucang ${key < orderInfo.order.ProductComment.food_score ? styles.active : ''}`}></i>
                    })}
                </div>
                <div className={styles.lineWrap}>
                    <label>旅行交通：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} className={`icon iconfont icon-shoucang ${key < orderInfo.order.ProductComment.traffic_score ? styles.active : ''}`}></i>
                    })}
                </div>
            </div>
        </section> :
            <div onClick={() => {
                setIsPublish(true);
            }} className={styles.footer} >
                <button className={styles.buy}>我要评论</button>
            </div>}

        {isPublish ? <Publish order_id={orderInfo.order.id} setIsPublish={setIsPublish} /> : false}
    </div >;
}

const Publish = (props) => {
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [journeyScore, setJurneyScore] = useState(0);
    const [trafficScore, setTrafficScore] = useState(0);
    const [foodScore, setFoodScore] = useState(0);
    const uploadFile = async (event) => {
        if (images.length <= 9) {
            const file = event.currentTarget.files && event.currentTarget.files[0];
            const res = await Services.page.imageUptoken();
            const token = v1();
            const timestamp = new Date().getTime();
            const saveKey = `user/media/${token}${timestamp}`;
            const observable = qiniu.upload(file, saveKey, res.data);
            const observer = {
                next(res) {
                    // ...
                    console.log(res);
                },
                error(err) {
                    // ...
                    console.warn(err);
                },
                complete(res) {
                    // ...
                    const bannerUrl = `${process.env.REACT_APP_QINIU_CDN}/${res.key}`;
                    setImages(images.concat(bannerUrl));
                },
            };
            observable.subscribe(observer);
        }
    }

    const submit = async () => {
        if (!content) {
            Actions.alert.show('请填写内容');
            return;
        }
        if (!images || images.length <= 0) {
            Actions.alert.show('请上传图片');
            return;
        }
        const result = await Services.order.comment({ traffic_score: trafficScore, journey_score: journeyScore, food_score: foodScore, order_id: props.order_id, content, attach: { images } });
        if (result) {
            await Actions.alert.show('发布成功');
            window.location.reload();
        }
    }

    return <div className={styles.publishContainer}>
        <section>
            <textarea maxLength={250} value={content} onChange={(event) => {
                setContent(event.target.value);
            }} placeholder='分享有趣的经历，帮助更多热爱旅行的人' className='textarea' />
            <div className={styles.media}>
                {images.map((item, key) => {
                    return <div key={key} className={styles.upload}>
                        <div className={styles.imageWrap}>
                            <img src={item} />
                        </div>
                        <i onClick={() => {
                            setImages(images.filter((k, f) => {
                                return f !== key;
                            }));
                        }} className={`icon iconfont icon-guanbixiao ${styles.close}`}></i>
                    </div>
                })}
                {images.length < 9 ? <label className={styles.upload}>
                    <input type='file' onChange={uploadFile} />
                    <i className='icon iconfont icon-paizhao1'></i>
                    <p>上传图片</p>
                </label> : false}



            </div>
            <div className={styles.rate}>
                <div className={styles.lineWrap}>
                    <label>行程安排：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} onClick={() => {
                            setJurneyScore(item);
                        }} className={`icon iconfont icon-shoucang ${key < journeyScore ? styles.active : ''}`}></i>
                    })}
                </div>
                <div className={styles.lineWrap}>
                    <label>饮食住宿：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} onClick={() => {
                            setFoodScore(item);
                        }} className={`icon iconfont icon-shoucang ${key < foodScore ? styles.active : ''}`}></i>
                    })}
                </div>
                <div className={styles.lineWrap}>
                    <label>旅行交通：</label>
                    {[1, 2, 3, 4, 5].map((item, key) => {
                        return <i key={key} onClick={() => {
                            setTrafficScore(item);
                        }} className={`icon iconfont icon-shoucang ${key < trafficScore ? styles.active : ''}`}></i>
                    })}
                </div>
            </div>
        </section>
        <div className={styles.footer} onClick={submit}>
            发 布
        </div>
    </div>;
}

const Pay = (props) => {
    const [payType, setPayType] = useState(null);
    const { orderInfo, getOrderDetail } = props;
    const submit = async () => {
        if (payType === 'WECHAT_MICRO') {
            const result = await Services.order.getWeixinPayUrl({ orderId: orderInfo.order.id });
            if (result.status > 0) {
                const { timeStamp: timestamp, nonceStr, package: packages, signType, paySign } = result.data.jsPay;
                const payParams = {
                    timestamp,
                    nonceStr,
                    package: packages,
                    signType,
                    paySign,
                    success: async (res) => {
                        // 支付成功后的回调函数
                        Actions.alert.show('支付成功!');
                        getOrderDetail();
                    }
                };
                window.wx.chooseWXPay(payParams);
            }
        } else {
            const result = await Services.order.getAlipayUrl({ orderId: orderInfo.order.id });
            if (result.status > 0) {
                var params = Object.keys(result.data.params).map((item) => {
                    return `${item}=${encodeURIComponent(result.data.params[item])}`;
                })
                window.location.href = `${result.data.url}?${params.join('&')}`;
            }
        }
    }
    return <div className={styles.container}>
        <section>
            <h1>请尽快支付订单！</h1>
            <h1>出行线路：{orderInfo.product.title}</h1>
            <h1>订 单 号：{orderInfo.order.order_no}</h1>
        </section>
        <section>
            <h1>订单倒计时：<strong></strong></h1>
        </section>
        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    支付方式
                </div>
            </div>
            <label className={styles.payLine}>
                <img className={styles.icon} src={wepayImage} alt='icon' />
                <label>微信支付</label>
                <div className={styles.right}>
                    <input name='paytype' checked={payType === 'WECHAT_MICRO'} onChange={() => {
                        setPayType('WECHAT_MICRO');
                    }} type='radio' />
                </div>
            </label>
            <label className={styles.payLine}>
                <img className={styles.icon} src={alipayImage} alt='icon' />
                <label>支付宝支付</label>
                <div className={styles.right}>
                    <input name='paytype' checked={payType === 'ALIPAY'} onChange={() => {
                        setPayType('ALIPAY');
                    }} type='radio' />
                </div>
            </label>
        </section>
        <div className={styles.footer}>
            <span className={styles.money}><small>¥</small> {orderInfo.order.confirm_price}
            </span>
            <button onClick={submit} className={styles.buy}>下一步</button>
        </div>
    </div>;
}

const FinishOrderInfo = (props) => {
    const { orderInfo, getOrderDetail } = props;
    const [travellers, setTravellers] = useState([]);
    const [insurances, setInsurances] = useState([]);
    const [isNeededInvoice, setIsNeededInvoice] = useState(false);
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [sendInvoiceType, setSendInvoiceType] = useState(1);
    const [contactAddress, setContactAddress] = useState('');
    const [deteilModalVisible, setDeteilModalVisible] = useState(false);

    const audits = travellers.filter(e => moment().diff(moment(e.birthday), 'year') >= 12);
    const children = travellers.filter(e => moment().diff(moment(e.birthday), 'year') < 12);

    const price = orderInfo.product.ProductPrices && orderInfo.product.ProductPrices[0];
    const auditsPrices = audits.length * parseFloat(price.sale_price);
    const childrenPrices = children.length * parseFloat(price.child_sale_price);

    const deliverPrice = sendInvoiceType === 1 && isNeededInvoice ? 15 : 0;
    const insurancesPrices = insurances.reduce((total, item) => {
        return total + parseFloat(item.price);
    }, 0);

    const submit = async () => {
        const attach = () => {
            const params = { is_needed_invoice: isNeededInvoice };
            if (isNeededInvoice) {
                params.invoice_title = invoiceTitle;
                params.send_invoice_type = sendInvoiceType;
            }
            params.contact_address = contactAddress;
            return params;
        }
        const res = await Services.order.update({
            insurance: insurances,
            children: children,
            audit: audits,
            orderId: orderInfo.order.id,
            attach: attach()

        });
        if (res.status > 0) {
            getOrderDetail();
        }
    }

    return < div className={styles.container} >
        <section>
            <h1>请填写订单信息！</h1>
            <h1>出行线路：{orderInfo.product.title}</h1>
            <h1>订 单 号：{orderInfo.order.order_no}</h1>
        </section>
        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    旅客信息 <small>{orderInfo.audit}成人/{orderInfo.children}儿童</small>
                </div>
            </div>
            {audits.concat(children).map((item, key) => {
                return <div key={key} className={styles.line}>
                    <label>{item.name} {moment().diff(moment(item.birthday), 'year') < 12 ? '(儿童)' : ''}</label>
                    <div className={styles.right}>
                        <span>{item.sex > 0 ? '男' : '女'} {format.idCard(item.idcard)}</span>

                        <button onClick={() => {
                            setTravellers(travellers.filter((e, f) => f !== key));
                        }}><i className='icon iconfont icon-shibai2'></i> 删除</button>

                    </div>
                </div>;
            })}

            <div className={styles.line}>
                <button className={styles.addTraveller} onClick={async () => {
                    const result = await Actions.travelModal.show().catch(e => false);
                    if (result) {
                        const isAudit = moment().diff(moment(result.birthday), 'year') >= 12
                        if ((!isAudit && orderInfo.children <= children.length) ||
                            (isAudit && orderInfo.audit <= audits.length)) {
                            Actions.alert.show(`${isAudit ? '成人' : '儿童'}不能超出订单人数`);
                        } else {
                            setTravellers(travellers.concat([result]));

                        }
                    }
                    console.log(result);
                }}>
                    <i className='icon iconfont icon-wode'></i>    添加出行人
                        </button>
            </div>
        </section>
        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    选择保险
                </div>
                <div className={styles.right}><i className='icon iconfont icon-xuzhixiao'></i>购买时请选择对应的保险产品</div>
            </div>
            {orderInfo.insurances.map((item, key) => {
                const isSelected = insurances.map(e => e.id).includes(item.id);
                return <div onClick={() => {
                    if (isSelected) {
                        setInsurances(insurances.filter(e => e.id !== item.id));
                    } else {
                        setInsurances(insurances.concat([item]));

                    }
                }} key={key} className={`${styles.line} ${styles.insuranceWrap}`}>
                    <label>
                        <i className={`icon iconfont ${isSelected ? 'icon-duigouzhong' : 'icon-duigou-weigouxuan'} ${isSelected ? styles.checked : ''}`} ></i>
                    </label>
                    <div className={styles.insuranceRight}>
                        <span>{item.name} <em>¥{item.price}</em>
                            <i className='icon iconfont icon-yuyinjiantou'></i>
                        </span>
                        <p>根据文旅局明文要求，游客需要购买相应的旅游意外保险，给自己一份保障，给家人一份安心。</p>
                    </div>
                </div>
            })}
        </section>
        <section>
            <div className={styles.sectionTitle}>
                <div className={styles.left}>
                    发票信息
                </div>
            </div>
            <div className={styles.line}>
                <label>是否开票</label>
                <div className={styles.ticketWrap}>
                    <div className={styles.control}>
                        <label><input checked={isNeededInvoice} onChange={() => {
                            setIsNeededInvoice(true);
                        }} type='radio' name='isNeededInvoice' /> 是</label>
                    </div><div className={styles.control}>
                        <label><input checked={!isNeededInvoice} onChange={() => {
                            setIsNeededInvoice(false);
                        }} type='radio' name='isNeededInvoice' /> 否</label>
                    </div>
                </div>
            </div>
            {isNeededInvoice ? <>
                <div className={styles.line}>
                    <label>发票抬头</label>
                    <div className={styles.right}>
                        <div className={styles.control}>
                            <input value={invoiceTitle} onChange={(event) => {
                                setInvoiceTitle(event.target.value);
                            }} type='text' placeholder='请输入发票抬头' />
                        </div>
                    </div>
                </div>
                <div className={styles.line}>
                    <label>取票方式</label>
                    <div className={styles.ticketWrap}>
                        <div className={styles.control}>
                            <label><input checked={sendInvoiceType === 1} onChange={() => {
                                setSendInvoiceType(1);
                            }} type='radio' /> 发票邮寄 <small> 邮寄需加收15元快递费</small></label>
                        </div>
                        <div className={styles.control}>
                            <label><input checked={sendInvoiceType === 2} onChange={() => {
                                setSendInvoiceType(2);
                            }} type='radio' /> 上门自取 <small> 上海松江区蔡家帮路18号8433室</small></label>
                        </div>
                    </div>
                </div>
                {sendInvoiceType === 1 ? <div className={styles.line}>
                    <label>收件地址</label>
                    <div className={styles.right}>
                        <input value={contactAddress} onChange={(event) => {
                            setContactAddress(event.target.value);
                        }} type='text' placeholder='请填写发票收件地址' />

                    </div>
                </div> : false}

            </> : false}

        </section>
        {
            deteilModalVisible ? <div className={styles.modal} onClick={() => {
                setDeteilModalVisible(false);
            }}>
                <div className={styles.modalBack}></div>
                <div className={styles.modalTitle}>
                    <div className={styles.left}></div>
                    <div className={styles.middle}>费用明细</div>
                    <div className={styles.right}>
                        <i className='iconfont icon-shibai2'></i>
                    </div>
                </div>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentTitle}>
                        <div className={styles.left}>基本费用</div>
                        <div className={styles.right}>¥{auditsPrices + childrenPrices}</div>
                    </div>
                    <div className={styles.modalContentSubTitle}>
                        <div className={styles.left}>成人</div>
                        <div className={styles.right}>¥{price.sale_price}*{audits.length}</div>
                    </div>
                    <div className={styles.modalContentSubTitle}>
                        <div className={styles.left}>儿童</div>
                        <div className={styles.right}>¥{price.child_sale_price}*{children.length}</div>
                    </div>
                    <div className={styles.modalContentTitle}>
                        <div className={styles.left}>保险费用</div>
                        <div className={styles.right}>¥{insurancesPrices}</div>
                    </div>
                    {insurances.map((item, key) => {
                        return <div key={key} className={styles.modalContentSubTitle}>
                            <div className={styles.left}>{item.name}</div>
                            <div className={styles.right}>¥{item.price}</div>
                        </div>
                    })}
                    {deliverPrice > 0 ? <>
                        <div className={styles.modalContentTitle}>
                            <div className={styles.left}>发票邮费</div>
                            <div className={styles.right}>¥{deliverPrice}</div>
                        </div>
                        <div className={styles.modalContentSubTitle}>
                            <div className={styles.left}>发票邮费</div>
                            <div className={styles.right}>¥{deliverPrice}</div>
                        </div></> : false}
                    <div className={styles.total}>
                        <strong>订单总额（在线支付）：</strong>
                        <em>¥{auditsPrices + childrenPrices + deliverPrice + insurancesPrices}</em>
                    </div>
                </div>
            </div> : false
        }
        <div className={styles.footer}>
            <span className={styles.money}><small>¥</small> {auditsPrices + childrenPrices + deliverPrice + insurancesPrices}
                <a className={styles.detail} onClick={() => {
                    setDeteilModalVisible(true);
                }}>明细</a>
            </span>
            {orderInfo.children === children.length && orderInfo.audit === audits.length ?
                <button onClick={submit} className={styles.buy}>下一步</button>
                : <button disabled className={styles.buy}>下一步</button>
            }

        </div>
    </div >;
}
