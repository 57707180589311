import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';
import { Actions } from 'reduxs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

const statusMap = ['全部', '获得', '消耗'];
const statusIcon = ['icon-dingdanjihe', 'icon-shikebiao', 'icon-qitadingdan', 'icon-dingdan', 'icon-dingdandaifukuan', 'icon-dingdanyichenggong', 'icon-dingdanchulizhong', 'icon-tuichu', 'icon-xiangqing']


export const Credit = (props) => {
    const { paramStatus } = useParams();
    const [limit] = useState(10);
    const [type, setType] = useState(paramStatus || 0);
    let [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [creditInfo, setCreditInfo] = useState(null);

    const history = useHistory();
    useEffect(() => {
        getCreditDetail();
    }, []);

    useEffect(() => {
        document.getElementById('root').addEventListener('scroll', onScroll);
        return () => {
            document.getElementById('root').removeEventListener('scroll', onScroll);
        }
    });
    useEffect(() => {
        getOrderList(true);
    }, [type]);

    const getCreditDetail = async () => {
        const result = await Services.user.creditDetail();
        if (result) {
            setCreditInfo(result.data);
        }
    }

    const onScroll = (event) => {
        if (event.target.scrollTop + window.screen.height - 20 > event.target.offsetHeight) {
            getOrderList();
        }
    }

    const getOrderList = async (refresh) => {
        if (refresh) {
            list = [];
        }
        if (!hasMore && !refresh) {
            return;
        }
        const result = await Services.user.myCredit({ offset: list.length, limit, type });
        if (result) {
            const newList = [].concat(list, result.data.rows)
            setList(newList);
            setHasMore(result.data.count > newList.length);
        }
    }

    const exchange = () => {
        const credit = creditInfo && creditInfo.current || 0;
        const canExchangeAmount = parseInt(credit / 2000);
        if (canExchangeAmount > 0) {
            Actions.modal.show(`可兑换${canExchangeAmount * 10}元优惠券，是否确定兑换？`, null, [{
                name: '再想想', click: () => {
                    Actions.modal.hide();
                }
            }, {
                name: '去兑换', click: async () => {
                    const result = await Services.user.exchangeCoupon();
                    if (result.status > 0) {
                        getCreditDetail();
                    }
                    Actions.modal.hide();
                }
            }]);
        } else {
            Actions.alert.show('没有足够的积分兑换优惠券');
        }
    };

    return < div className={styles.container} >
        <section>
            <div className={styles.top}>
                <Link className={styles.item} >
                    <h2>我的积分 </h2>
                    <p>{creditInfo && creditInfo.current || 0}<small>分</small>
                        <button onClick={() => {
                            exchange();
                        }}>优惠券</button>
                    </p>
                </Link>
                <div className={styles.item}>
                    <h2>即将过期</h2>
                    <p>{creditInfo && creditInfo.willExpired || 0}<small>分</small></p>
                </div>

                <Link className={styles.item} >
                    <h2>已过期</h2>
                    <p>{creditInfo && creditInfo.expired || 0}<small>分</small></p>
                </Link>
            </div>
            <div className={styles.order}>
                {statusMap.map((item, key) => {
                    return <div onClick={() => {
                        setType(key);
                    }} className={`${styles.orderWrap} ${key.toString() === type.toString() ? styles.active : ''}`}>
                        <div className={styles.orderItem}>
                            <i className={'icon iconfont ' + statusIcon[key]}></i>
                            <p>{item}</p>
                        </div>
                    </div>;
                })}
            </div>
        </section>
        <section>
            {list.map((item, key) => {
                const isExpired = moment(item.expire).diff(moment()) <= 0;
                return <div key={key} className={`${styles.credit} ${isExpired ? styles.expired : ''}`}>
                    <div className={styles.creditWrap}>
                        <div className={styles.left}>
                            <h3>{item.comment} {isExpired ? <i>已过期</i> : false}</h3>
                            <p>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                        </div>
                        <div className={styles.right}>
                            <span className={item.type === 'in' ? styles.red : styles.green}>{item.type === 'in' ? '+' : '-'}{item.amount}</span>
                        </div>
                    </div>
                    <p className={styles.expire}>有效期至：{moment(item.expire).format('YYYY-MM-DD HH:mm:ss')}</p>
                </div>
            })}
        </section>
    </div>

}