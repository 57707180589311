const ua = navigator.userAgent.toLowerCase();

export const check = {
    isWechat: () => {
        return ua.indexOf('micromessenger') > -1;
    },
    isApp: () => {
        return !!window.ReactNativeWebView;
    },
    isH5: () => {
        return !check.isWechat()  && !check.isApp();
    }
}

export const name = () => {
    if (check.isWechat()) {
        return 'Wechat';
    } else if (check.isApp()) {
        return 'GuApp';
    } else {
        return 'Wap';
    }
}
