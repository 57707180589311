import React from 'react';
import styles from './rate.module.scss';
export default (props) => {
    const { className, ...rest } = props;
    const { user, product_comment_attaches, content, journey_score } = rest;
    return <div className={`${styles.wrap} ${className ? className : ''}`} {...rest}>
        <div className={styles.thumbnail}>
            <img src={user.avatar} />
        </div>
        <div className={styles.right}>
            <h4>{JSON.parse(user.name)}</h4>
            <p>
                {[1, 2, 3, 4, 5].map((item, key) => {
                    return <i key={key} className={`icon iconfont ${key < journey_score ? ' icon-shoucang' : ' icon-shoucang1'}`}></i>
                })}
            </p>
            <p>
                {content}
            </p>
            <div className={styles.imageBox}>
                {product_comment_attaches.map((item, key) => {
                    return <img src={item.wap_url} key={key} alt="" />
                })}

            </div>
        </div>
    </div>;
}
