import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import styles from './index.module.scss';
import { Actions } from 'reduxs';

export const Confirm = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [product, setProduct] = useState();
    const [sku, setSku] = useState({});
    const [prices, setPrices] = useState([]);
    const [months, setMonths] = useState([moment()]);
    const [thisMonth, setThisMonth] = useState(moment());
    const [travelAt, setTravelAt] = useState();
    const [days, setDays] = useState([]);

    const [audit, setAudit] = useState(0);
    const [child, setChild] = useState(0);

    const [deteilModalVisible, setDeteilModalVisible] = useState(false);

    useEffect(() => {
        const { state } = location;
        const { product } = state;
        console.log(product);
        if (!product) {
            history.goBack();
            return;
        }
        setProduct(product);
        if (product.ProductSkus && product.ProductSkus.length > 0) {
            setSku(product.ProductSkus[0]);
            if (state.travel_at) {
                const [m, d] = state.travel_at.split('/');
                const y = moment().year();
                setTravelAt(moment([y, m - 1, d]).format('YYYY-MM-DD'));
            } else {
                const sku = product.ProductSkus[0];
                if (sku.ProductPrices && sku.ProductPrices.length > 0) {
                    setTravelAt(sku.ProductPrices[0]);
                }
            }
        }
    }, [location]);

    useEffect(() => {
        const prices = (sku.ProductPrices || []).sort((a, b) => {
            return moment(a.travel_at).diff(moment(b.travel_at));
        });
        setPrices(prices);
        if (prices.length > 0) {
            const beginMonth = moment(prices[0].travel_at);
            const endMonth = moment(prices[prices.length - 1].travel_at);
            const diffMonths = endMonth.diff(beginMonth, 'month');
            let items = [];
            for (let i = 0; i < diffMonths + 1; i++) {
                items.push(moment(beginMonth.format('YYYY-MM')).add(i, 'month'));
            }
            setMonths(items);
            setThisMonth(beginMonth);
        }
    }, [sku]);

    useEffect(() => {
        const monthDays = [];
        const _thisMonth = thisMonth.clone();
        const firstDayNumber = _thisMonth.set('date', 1).weekday();
        console.log(firstDayNumber);
        for (let i = 0; i < firstDayNumber; i++) {
            monthDays.push(null);
        }
        const monthDayCount = _thisMonth.add(1, 'months').set('date', 0).date();
        console.log(monthDayCount);
        for (let i = 0; i < monthDayCount; i++) {
            const date = _thisMonth.set('date', i + 1);
            const thisPrice = prices.filter(a => a.travel_at === date.format('YYYY-MM-DD'));
            if (thisPrice.length > 0) {
                monthDays.push({ date: date.format('DD'), price: thisPrice[0] })
            } else {
                monthDays.push({ date: date.format('DD') });
            }
            setDays(monthDays);
        }
    }, [thisMonth]);

    useEffect(() => {

    }, [travelAt, prices]);

    const thisPrices = prices.filter((e) => {
        return e.travel_at === travelAt
    })[0];
    console.log(travelAt);

    if (product) {
        return <div className={styles.container}>
            <h1 className={styles.title}>
                {product.title}
            </h1>

            <section>
                <h2>产品类型</h2>
                <ul className={styles.select}>
                    {product.ProductSkus.map((item, key) => {
                        return <li onClick={()=>{
                            setSku(item);
                        }} key={key} className={item.id === sku.id ? styles.active : ''}>{item.name}</li>
                    })}
                </ul>
            </section>
            <section>
                <h2>选择日期 <small>请至少提前1天预订 </small></h2>
                <div className={styles.datepicker}>
                    <ul className={styles.month}>
                        {months.map((item, key) => {
                            return <li onClick={() => {
                                setThisMonth(item);
                            }} key={key}
                                className={item.format('YYYYMM') === thisMonth.format('YYYYMM') ? styles.active : ''}
                            >
                                {item.format('MM月')}
                            </li>
                        })}
                    </ul>
                    <ul className={styles.week}>
                        <li>日</li>
                        <li>一</li>
                        <li>二</li>
                        <li>三</li>
                        <li>四</li>
                        <li>五</li>
                        <li>六</li>
                    </ul>

                    <ul className={styles.day}>
                        {days.map((item, key) => {
                            if (item) {
                                return <li key={key} onClick={() => {
                                    if (item.price && item.price.stock > 0) {
                                        setTravelAt(item.price.travel_at);
                                    }
                                }} className={item.price ? (item.price.travel_at === travelAt ? styles.active : '') : styles.disabled}>
                                    <p>{item.date}</p>
                                    {item.price ? <div className={styles.detail}>
                                        <p className={styles.money}>¥{parseFloat(item.price.sale_price).toPrecision()}</p>
                                        <p className={styles.stock}>余{item.price.stock}</p>
                                    </div> : false}
                                </li>;
                            } else {
                                return <li key={key}></li>;
                            }
                        })}
                    </ul>
                </div>
            </section>
            {thisPrices ? <section>
                <div className={styles.selection}>
                    <div className={styles.left}>
                        <h2>成人</h2>
                        <p>¥{thisPrices.sale_price}</p>
                    </div>
                    <div className={styles.right}>
                        <i onClick={() => {
                            setAudit(Math.max(0, audit - 1));
                        }}>-</i>
                        <span>{audit}</span>
                        <i onClick={() => {
                            setAudit(audit + 1);
                        }}>+</i>
                    </div>
                </div>
                <div className={styles.selection}>
                    <div className={styles.left}>
                        <h2>儿童</h2>
                        <p>¥{thisPrices.child_sale_price}</p>
                    </div>
                    <div className={styles.right}>
                        <i onClick={() => {
                            setChild(Math.max(0, child - 1));
                        }}>-</i>
                        <span>{child}</span>
                        <i onClick={() => {
                            setChild(child + 1);
                        }}>+</i>
                    </div>
                </div>
            </section> : false}

            <DetailModal setDeteilModalVisible={setDeteilModalVisible} visible={deteilModalVisible} child={child} audit={audit} thisPrice={thisPrices} />
            <FooterRender sku={sku} product={product} setDeteilModalVisible={setDeteilModalVisible} child={child} audit={audit} thisPrice={thisPrices} />

        </div>
    } else {
        return false;
    }
}

const DetailModal = (props) => {
    const { visible, child, audit, thisPrice, setDeteilModalVisible } = props;
    if (visible && thisPrice) {
        const auditPrice = parseFloat(audit * thisPrice.sale_price);
        const childPrice = parseFloat(child * thisPrice.child_sale_price);
        return <div className={styles.modal} onClick={() => {
            setDeteilModalVisible(false);
        }}>
            <div className={styles.modalBack}></div>
            <div className={styles.modalTitle}>
                <div className={styles.left}></div>
                <div className={styles.middle}>费用明细</div>
                <div className={styles.right}>
                    <i className='iconfont icon-shibai2'></i>
                </div>
            </div>
            <div className={styles.modalContent}>
                <div className={styles.modalContentTitle}>
                    <div className={styles.left}>基本费用</div>
                    <div className={styles.right}>¥{auditPrice + childPrice}</div>
                </div>
                <div className={styles.modalContentSubTitle}>
                    <div className={styles.left}>成人</div>
                    <div className={styles.right}>¥{thisPrice.sale_price}*{audit}</div>
                </div>
                <div className={styles.modalContentSubTitle}>
                    <div className={styles.left}>儿童</div>
                    <div className={styles.right}>¥{thisPrice.child_sale_price}*{child}</div>
                </div>
                <div className={styles.total}>
                    <strong>订单总额（在线支付）：</strong>
                    <em>¥{auditPrice + childPrice}</em>
                </div>
            </div>
        </div>
    } else {
        return false;
    }

}

const FooterRender = (props) => {
    const { product, sku, child, audit, thisPrice, setDeteilModalVisible } = props;
    const history = useHistory();
    if (thisPrice && audit) {
        const total = parseFloat(audit * thisPrice.sale_price) + parseFloat(child * thisPrice.child_sale_price);
        return <div className={styles.footer}>
            <span className={styles.money}><small>¥</small>
                {total}
                <a onClick={() => {
                    setDeteilModalVisible(true);
                }} className={styles.detail}>明细</a>
            </span>
            <button className={styles.share} onClick={() => {
                Actions.kf.show();
            }}><i className="icon iconfont icon-kefu"></i>客服</button>
            <button onClick={() => {
                history.replace('/weixin/order/info', {
                    product, thisPrice, child, audit, sku
                })
            }} className={styles.buy}>下一步</button>
        </div>
    } else {
        return <div className={styles.footer}>
            <span className={styles.money}><small>¥</small>
                0
                <a onClick={() => {
                    setDeteilModalVisible(true);
                }} className={styles.detail}>明细</a>
            </span>
            <button className={styles.share} onClick={() => {
                Actions.kf.show();
            }}><i className="icon iconfont icon-kefu"></i>客服</button>
            <button disabled className={styles.buy}>下一步</button>
        </div>
    }
}