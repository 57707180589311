export const reduxToStorage = ({ getState }) => {
    return (next) => {
        return (action) => {
            const returnValue = next(action);
            const nextState = getState();
            new Promise((resolve) => {

                const saveState = { user: nextState.user, travel: nextState.travel, product: nextState.product, sms: nextState.sms };
                localStorage.setItem('SHGCTS_STORAGE', JSON.stringify(saveState));
                resolve();
            });
            return returnValue;
        };
    };
};
