import React from 'react';
import { Actions } from 'reduxs';
import Services from 'services';
import imgTitle from './img/Integral_draw-text.png';
import iconIntegral10 from './img/Integral_draw-icon-10integral.png';
import iconIntegral20 from './img/Integral_draw-icon-20integral.png';
import iconIntegral48 from './img/Integral_draw-icon-48integral.png';
import iconIntegral88 from './img/Integral_draw-icon-88integral.png';
import iconIntegral888 from './img/Integral_draw-icon-888integral.png';
// import iconPhone from './img/Integral_draw-icon-10phone.png';
// import iconIqiY from './img/Integral_draw-icon-iqiy.png';
import iconDyson from './img/Integral_draw-icon-dyson.png';
import iconMbp from './img/Integral_draw-icon-mbp.png';
import iconWinning from './img/Integral_draw-icon-winning@2x.png';
import modalBackground from './img/Lucky_draw-window-winning.png';

import './index.scss';

const buttons = [
    {
        type: 'gift', img: iconMbp, name: '苹果笔记本', value: '1', step: 0, click: () => {
        }
    },
    { type: 'integral', img: iconIntegral88, name: '35积分', value: '35', step: 1 },
    { type: 'integral', img: iconIntegral10, name: '10积分', value: '10', step: 2 },
    { type: 'integral', img: iconIntegral48, name: '48积分', value: '48', step: 7 },
    { type: 'start' },
    { type: 'none', img: iconWinning, name: '谢谢参与', step: 3 },
    { type: 'integral', img: iconIntegral20, name: '18积分', value: '18', step: 6 },
    { type: 'integral', img: iconIntegral888, name: '888积分', value: '888', step: 5 },
    {
        type: 'gift', img: iconDyson, name: '戴森吸尘器', value: '2', step: 4, click: () => {
        }
    },
];

let timer;
let runner;
export class LotteryPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            stars: [],
            count: 0,
            isBegin: false,
            endSteps: 0,
            thisStep: 0,
            reward: null,
            playTimes: 0,
            allTimes: 1
        };
        this.Actions = context.Actions;
        this.Services = context.Services;

        this.lightChange = this.lightChange.bind(this);

    }
    async componentDidMount() {
        const stars = [];
        for (var i = 0; i < 34; i++) {
            stars.push(i);
        }
        this.setState({
            stars
        });
        //获取用户信息
        // const result = {
        //     point: 40,
        //     playTimes: 0,
        //     allTimes: 5,
        //     costPoint: 20
        // };
        timer = setInterval(this.lightChange, 1000);
        const result = await Services.user.lottoryInit();
        console.log(result);
        if (result.status > 0) {
            this.setState({ count: result.data.count });
        }
        // const fetchResult = await Jsbridge.runAction('activity', 'lotteryInit', new Date().getTime(), []);
        // if (fetchResult.status > 0) {
        //     const result = fetchResult.data;
        //     this.setState({ point: result.point, playTimes: result.playTimes, allTimes: result.allTimes, costPoint: result.costPoint });
        // }
    }

    componentWillUnmout() {
        clearInterval(timer);
        clearTimeout(runner);
    }

    lightChange() {
        let { showLight } = this.state;
        this.setState({ showLight: !showLight });
    }

    async getAward(index) {
        //抽奖
        // const result = {
        //     index: parseInt(Math.random() * 8),
        //     value: 1,
        //     type: 'integral'
        // };
        const { count, allTimes, thisStep } = this.state;
        if (allTimes - count > 0) {
            const result = await Services.user.lottory();
            if (result.status > 0) {
                const diff = Math.abs(8 - thisStep % 8 + (result.data.reward.index));
                const endSteps = 24 + diff;
                this.setState({ isBegin: true, endSteps: endSteps, count: count + 1, reward: result.data.reward }, () => {
                    runner = setTimeout(this.startAnimation.bind(this), 200);
                });
            }
        } else {
            Actions.alert.show('今天机会已用完，请明天再来');
        }

    }

    async startAnimation(interval = 500) {
        let { thisStep, endSteps } = this.state;
        console.log(thisStep, endSteps);
        if (endSteps <= 0) {
            clearTimeout(runner);
            this.reward(thisStep);
            return;
        } else {
            endSteps = endSteps - 1;
            thisStep = thisStep + 1;
            this.setState({ endSteps, thisStep });
        }
        if (endSteps < 5) {
            interval = Math.min(1000, interval + 200);
        } else {
            interval = Math.max(50, interval - 200);
        }
        runner = setTimeout(this.startAnimation.bind(this, interval), interval);
    }

    async reward() {
        let { reward, point } = this.state;
        //结束抽奖，给出提示
        const thisItem = buttons.filter((item) => { return parseInt(reward.index) % 8 === item.step })[0];

        if (thisItem.type === 'none') {
            Actions.alert.show('很遗憾，这次没有中奖，请再试试吧！');
            this.setState({ isBegin: false });
            return;
        }
        await Actions.modal.show(`恭喜您中得${thisItem.name}\r\n可以在积分明细中查看`, '温馨提示');

        if (thisItem.type === 'integral') {
            point = point + parseInt(thisItem.value, 10);
        }

        this.setState({ isBegin: false });

    }

    render() {
        const { stars, count, showLight, isBegin, thisStep, point, playTimes, allTimes } = this.state;
        return <div className='integral-draw-wrap'>
            <div className='title'>
                <img src={imgTitle} />
            </div>
            <div className='draw-wrap'>
                <div className='draw-title'>今天还可以抽{allTimes - count}次</div>
                <div className={showLight ? 'draw-border' : 'draw-border light'}>
                    {stars.map((item, key) => {
                        return <i key={key} className='circle'></i>;
                    })}
                    {isBegin ? <div className='draw-mask'></div> : false}
                    {buttons.map((button, key) => {
                        if (button.type === 'start') {
                            return <div className='draw-start' key={key} onClick={this.getAward.bind(this, 0)}>
                                <h2>开始抽奖</h2>
                                {/* <p>{costPoint > 0 ? '-' + costPoint : costPoint}积分</p> */}
                            </div>
                        }
                        return <div onClick={() => {
                            if (button.click && typeof (button.click === 'function')) {
                                button.click();
                            }
                        }} className={thisStep % 8 === button.step ? 'draw-button active' : 'draw-button'} key={key}>
                            <img src={button.img} />
                            <p>{button.name}</p>
                        </div>;
                    })}

                </div>
            </div>
            <div className='footer'>
                <button className='award' onClick={async () => {
                    if (isBegin) {
                        return;
                    }
                    // const result = await Jsbridge.runAction('popup', 'show', new Date().getTime(), [
                    //     [
                    //         {
                    //             name: '兑换券获奖记录',
                    //         },
                    //         {
                    //             name: '积分获奖记录',
                    //         }
                    //     ]
                    // ]);
                    // if (result === '兑换券获奖记录') {
                    //     Jsbridge.navigate('ExchangeRecord');
                    // } else {
                    //     Jsbridge.navigate('IntegralDetail');
                    // }
                }}>获奖记录</button>
                <button className='award' onClick={() => {
                    if (isBegin) {
                        return;
                    }
                    // Jsbridge.open(`${window.location.origin}${window.location.pathname}/#/jkt/activity/credit/lotteryRule`);
                }}>活动规则</button>
            </div>

        </div>;
    }
}
