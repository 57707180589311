var Wi = [
    7,
    9,
    10,
    5,
    8,
    4,
    2,
    1,
    6,
    3,
    7,
    9,
    10,
    5,
    8,
    4,
    2,
    1
]; // 加权因子
var ValideCode = [
    1,
    0,
    10,
    9,
    8,
    7,
    6,
    5,
    4,
    3,
    2
]; // 身份证验证位值.10代表X
function IdCardValidate(idCard) {
    idCard = trim(idCard.replace(/ /g, "")); //去掉字符串头尾空格
    if (idCard.length == 15) {
        return isValidityBrithBy15IdCard(idCard); //进行15位身份证的验证
    } else if (idCard.length == 18) {
        var a_idCard = idCard.split(""); // 得到身份证数组
        if (isValidityBrithBy18IdCard(idCard) && isTrueValidateCodeBy18IdCard(a_idCard)) { //进行18位身份证的基本验证和第18位的验证
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
/**
	 * 判断身份证号码为18位时最后的验证位是否正确
	 * @param a_idCard 身份证号码数组
	 * @return
	 */
function isTrueValidateCodeBy18IdCard(a_idCard) {
    var sum = 0; // 声明加权求和变量
    if (a_idCard[17].toLowerCase() == 'x') {
        a_idCard[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
    }
    for (var i = 0; i < 17; i++) {
        sum += Wi[i] * a_idCard[i]; // 加权求和
    }
    if (a_idCard[17] == ValideCode[sum % 11]) {
        return true;
    } else {
        return false;
    }
}
/**
	  * 验证18位数身份证号码中的生日是否是有效生日
	  * @param idCard 18位书身份证字符串
	  * @return
	  */
function isValidityBrithBy18IdCard(idCard18) {
    var year = idCard18.substring(6, 10);
    var month = idCard18.substring(10, 12);
    var day = idCard18.substring(12, 14);
    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 这里用getFullYear()获取年份，避免千年虫问题
    if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
        return false;
    } else {
        return true;
    }
}
/**
	   * 验证15位数身份证号码中的生日是否是有效生日
	   * @param idCard15 15位书身份证字符串
	   * @return
	   */
function isValidityBrithBy15IdCard(idCard15) {
    var year = idCard15.substring(6, 8);
    var month = idCard15.substring(8, 10);
    var day = idCard15.substring(10, 12);
    var temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
    if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
        return false;
    } else {
        return true;
    }
}
//去掉字符串头尾空格
function trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, "");
}
/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */
export const validate = {
    mobile: (name) => {
        name = name || '';
        let reg = new RegExp(/^1\d{2}\s?\d{4}\s?\d{4}$/);
        return reg.test(name.replace(/\s/g, ''));
    },
    idcard: (idcard) => {
        const isIdcard = IdCardValidate(idcard);
        if (isIdcard) {
            return getBirthdayFromIdCard(idcard);
        } else {
            return false;
        }
    },
    username: (val) => {
        let reg = new RegExp(/^([\u4e00-\u9fa5]){2,7}$/); //只能是中文，长度为2-7位
        return reg.test(val.replace(/\s/g, ''));
    },
    company: (val) => {
        let reg = new RegExp(/^([\u4e00-\u9fa5]){4,50}$/);
        return reg.test(val.replace(/\s/g, ''));
    },
    dept: (val) => {
        let reg = new RegExp(/^([\u4e00-\u9fa5]){3,50}$/);
        return reg.test(val.replace(/\s/g, ''));
    },
    email: (val = '') => {
        let reg = new RegExp(/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/);
        return reg.test(val.replace(/\s/g, ''));
    },
    code: (val, length) => {
        let reg = new RegExp(`^[a-zA-Z0-9]{${length}}$`);
        return reg.test(val.replace(/\s/g, ''));
    },
    password: (val) => {
        let reg = new RegExp(/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)[a-zA-Z0-9\W_]{6,20}$/);
        return reg.test(val.replace(/\s/g, ''));
    },
};
const getBirthdayFromIdCard = (idCard) => {
    var birthday = "";
    if (idCard) {
        if (idCard.length === 15) {
            birthday = "19" + idCard.substr(6, 6);
        } else if (idCard.length === 18) {
            birthday = idCard.substr(6, 8);
        }

        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }

    return birthday;
};