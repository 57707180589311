/**
 *
 * @author by z-benjamin on 2017/5/16.
 * @name index
 *
 */
import Fetch from './fetch';
import * as Util from './util';
import * as Platform from './platform';
import * as Hooks from './hooks';

export default {
    Fetch,
    Util,
    Platform,
    Hooks,
};
