import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

const statusMap = ['全部', '获得', '消耗'];
const statusIcon = ['icon-dingdanjihe', 'icon-shikebiao', 'icon-qitadingdan', 'icon-dingdan', 'icon-dingdandaifukuan', 'icon-dingdanyichenggong', 'icon-dingdanchulizhong', 'icon-tuichu', 'icon-xiangqing']


export const Task = (props) => {
    const { paramStatus } = useParams();
    const [limit] = useState(10);
    const [type, setType] = useState(paramStatus || 0);
    const history = useHistory();
    let [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [creditInfo, setCreditInfo] = useState(null);

    useEffect(() => {
        getCreditDetail();
    }, []);

    useEffect(() => {
        document.getElementById('root').addEventListener('scroll', onScroll);
        return () => {
            document.getElementById('root').removeEventListener('scroll', onScroll);
        }
    });
    useEffect(() => {
        getOrderList(true);
    }, [type]);

    const getCreditDetail = async () => {
        const result = await Services.user.creditDetail();
        if (result) {
            setCreditInfo(result.data);
        }
    }

    const onScroll = (event) => {
        if (event.target.scrollTop + window.screen.height - 20 > event.target.offsetHeight) {
            getOrderList();
        }
    }

    const getOrderList = async (refresh) => {
        if (refresh) {
            list = [];
        }
        if (!hasMore && !refresh) {
            return;
        }
        const result = await Services.user.myCredit({ offset: list.length, limit, type });
        if (result) {
            const newList = [].concat(list, result.data.rows)
            setList(newList);
            setHasMore(result.data.count > newList.length);
        }
    }

    return < div className={styles.container} >
        <section>
            <div className={styles.top}>
                <Link className={styles.item} to='/weixin/user/credit'>
                    <h2>我的积分</h2>
                    <p>{creditInfo && creditInfo.current || 0}<small>分</small></p>
                </Link>
                <div className={styles.item}>
                    <h2>即将过期</h2>
                    <p>{creditInfo && creditInfo.willExpired || 0}<small>分</small></p>
                </div>

                <Link className={styles.item} to='/weixin/user/favProducts'>
                    <h2>已过期</h2>
                    <p>{creditInfo && creditInfo.expired || 0}<small>分</small></p>
                </Link>
            </div>
        </section>
        <section>
            <div className={styles.title}><h2>做任务赚积分</h2></div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont  icon-menpiao'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>旅游消费金额</h3>
                        <p>消费1元对应1个积分</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/home');
                        }}>去消费</button>
                    </div>
                </div>
            </div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont icon-guojihuochepiao'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>线路评论</h3>
                        <p>好评并字数在50字以上，奖励1000积分</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/home');
                        }}>去评论</button>
                    </div>
                </div>
            </div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont icon-shangwu'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>分享游记</h3>
                        <p>上传五张以上图片，奖励1000积分</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/user/publish/publish');
                        }}>去分享</button>
                    </div>
                </div>
            </div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont icon-youyongchi'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>邀请其他客户注册</h3>
                        <p>奖励1000积分/1个会员注册</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/home');
                        }}>去邀请</button>
                    </div>
                </div>
            </div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont icon-huabeizhangdan'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>微信转发旅游线路</h3>
                        <p>奖励1000积分/5条线路</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/home');
                        }}>去转发</button>
                    </div>
                </div>
            </div>
            <div className={`${styles.credit}`}>
                <div className={styles.creditWrap}>
                    <div className={styles.icon}>
                        <i className='icon iconfont icon-jiaoxing'></i>
                    </div>
                    <div className={styles.left}>
                        <h3>抽奖优惠活动</h3>
                        <p>获取抽奖金额对应的积分</p>
                    </div>
                    <div className={styles.right}>
                        <button onClick={() => {
                            history.push('/weixin/user/lottory');
                        }}>去抽奖</button>
                    </div>
                </div>
            </div>
        </section>
    </div>

}