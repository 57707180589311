import { GET_COUPON_SMS_COUNTDOWN, USER_LOGIN_SMS_COUNTDOWN } from '../../../constants';
import * as Immutable from 'immutable';

const initialState = Immutable.fromJS({
    couponCounter: {
        'expire': null,
    },
    userLoginCounter: {
        'expire': null
    }
});

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function sms(state = initialState, action) {
    switch (action.type) {
        case GET_COUPON_SMS_COUNTDOWN:
            return state.merge({ couponCounter: action.data });

        case USER_LOGIN_SMS_COUNTDOWN:
            return state.merge({ userLoginCounter: action.data })
        default:
            return state;
    }
}
