export const product = {
    detail: {
        method: 'get',
        url: '/wxApi/product/detail'
    },
    comments: {
        method: 'post',
        url: '/wxApi/product/comments'
    }

};  