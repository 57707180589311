export const article = {
    detail: {
        url: '/wxApi/article/detail',
        method: 'get'
    },
    list: {
        url: '/wxApi/article/list',
        method: 'get'
    },

}