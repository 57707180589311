import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DatePicker } from 'antd-mobile';
import styles from './index.module.scss';
import Services from 'services';
import { Actions } from 'reduxs';
import moment from 'moment';
import successImage from 'images/icon/success.png';

export default () => {
    const [type, setType] = useState(0);
    const typeName = (thisType) => {
        return ['个人定制', '公司定制', '会务年会', '租车服务'][thisType];
    }
    const [departure, setDeparture] = useState('');
    const [target, setTarget] = useState('');
    const [travelStart, setTravelStart] = useState();
    const [travelEnd, setTravelEnd] = useState();
    const [amount, setAmount] = useState(0);
    const [childAmount, setChildAmount] = useState(0);
    const [budget, setBudget] = useState();
    const [contact, setContact] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [wechat, setWechat] = useState('');
    const [other, setOther] = useState('');

    const [company, setCompany] = useState('');
    const [car, setCar] = useState();
    const [carSeats, setCarseats] = useState();

    const [isSuccess, setIsSuccess] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            const { type: _type, departure: _departure, target: _target, mobile: _mobile } = location.state;
            setType(_type);
            setDeparture(_departure);
            setTarget(_target);
            setMobile(_mobile);
        }
    }, [location]);

    const travelEndRef = useRef();

    const submit = async () => {
        if (!departure) {
            Actions.alert.show('请填写出发地')
            return;
        }
        if (!target) {
            Actions.alert.show('请填写目的地')
            return;
        }
        if (!travelStart || !travelEnd) {
            Actions.alert.show('请填写出行日期')
            return;
        }
        if (!amount) {
            Actions.alert.show('请填写出行人数')
            return;
        }
        if (!contact) {
            Actions.alert.show('请填写联系人姓名')
            return;
        }
        if (!mobile) {
            Actions.alert.show('请填写联系人电话')
            return;
        }
        if (!budget) {
            Actions.alert.show('请填写预算')
            return;
        }
        const result = await Services.page.specialSubmit({
            type,
            target,
            departure,
            travel_at_start: travelStart,
            travel_at_end: travelEnd,
            audit_amount: amount,
            child_amount: childAmount,
            budget,
            other,
            contact,
            mobile,
            email,
            wechat,
            company,
            car,
            car_seats: carSeats

        });
        if (result) {
            setIsSuccess(true);
        }
    }
    if (isSuccess) {
        return <div className={styles.success}>
            <div className={styles.successImage}>
                <img src={successImage} alt='成功' />
            </div>
            <h1>定制意向已提交成功</h1>
            <p>您的预订信息已提交，感谢选择上海金中旅，专业定制师马上与您联系，请保持电话畅通。</p>
        </div>;
    }

    return <div className={styles.container}>
        <div className={styles.title}>
            快速填写定制需求
        </div>
        <section>
            <h2>行程需求 <small>补充更多信息，匹配更适合的定制师</small></h2>
            <div className={styles.row}>
                <label>出发地</label>
                <div className={styles.right}>
                    <input type="text" placeholder='请填写出发地' value={departure} onChange={(event) => {
                        setDeparture(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>目的地</label>
                <div className={styles.right}>
                    <input type="text" placeholder='请填写目的地' value={target} onChange={(event) => {
                        setTarget(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>出行日期</label>
                <div className={styles.right}>
                    <div className={styles.line}>
                        <DatePicker mode='date' title='请选择起始日期' onChange={(date) => {
                            setTravelStart(moment(date).format('YYYY-MM-DD'));
                        }}>
                            <input className={styles.date} type="text" placeholder='请选择起始日期' value={travelStart} />
                        </DatePicker>
                        <span>至</span>
                        <DatePicker ref={travelEndRef} mode='date' title='请选择结束时间' onChange={(date) => {
                            setTravelEnd(moment(date).format('YYYY-MM-DD'));
                        }}>
                            <input className={styles.date} type="text" placeholder='请选择结束日期' value={travelEnd} />
                        </DatePicker>
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <label>出行人数</label>
                <div className={styles.right}>
                    <div className={styles.line}>
                        成人
                        <div className={styles.numWrap}>
                            <button onClick={() => {
                                setAmount(Math.max(0, amount - 1));
                            }}>-</button>
                            <input type="number" placeholder='人数' value={amount} onChange={(event) => {
                                setAmount(event.target.value);
                            }} />
                            <button onClick={() => {
                                setAmount(amount + 1);
                            }}>+</button>
                        </div>
                    </div>
                    <div className={styles.line}>
                        儿童
                        <div className={styles.numWrap}>
                            <button onClick={() => {
                                setChildAmount(Math.max(0, childAmount - 1));
                            }}>-</button>
                            <input type="number" placeholder='人数' value={childAmount} onChange={(event) => {
                                setChildAmount(event.target.value);
                            }} />
                            <button onClick={() => {
                                setChildAmount(childAmount + 1);
                            }}>+</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.row}>
                <label>预算（元）</label>
                <div className={styles.right}>
                    <input type="text" placeholder='请填写预算' value={budget} onChange={(event) => {
                        setBudget(event.target.value);
                    }} />
                </div>
            </div>
        </section>

        <section>
            <h2>联系信息 <small>补充更多信息，匹配更适合的定制师</small></h2>
            <div className={styles.row}>
                <label>联系人</label>
                <div className={styles.right}>
                    <input type="text" placeholder='必填，定制师怎么称呼您' value={contact} onChange={(event) => {
                        setContact(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>手机号</label>
                <div className={styles.right}>
                    <input type="text" placeholder='必填，方便定制师与您联络' value={mobile} onChange={(event) => {
                        setMobile(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>电子邮箱</label>
                <div className={styles.right}>
                    <input type="text" placeholder='选填，方便接收行程相关资料' value={email} onChange={(event) => {
                        setEmail(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>微信号</label>
                <div className={styles.right}>
                    <input type="text" placeholder='选填，方便接收行程相关资料' value={wechat} onChange={(event) => {
                        setWechat(event.target.value);
                    }} />
                </div>
            </div>
            <div className={styles.row}>
                <label>其他需求</label>
                <div className={styles.right}>
                    <textarea value={other} onChange={(event) => {
                        setOther(event.target.value);
                    }} placeholder='选填，您可以在此输入个性化需求，如：同行人中是否含婴儿、老人，希望定制师何时联系您，对酒店、景点、出行方式的具体要求等'>
                    </textarea>
                </div>
            </div>
        </section>
        <footer>
            <button onClick={submit} className={styles.submit}>提 交 意 向</button>
        </footer>
    </div>;
}