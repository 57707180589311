import React, { useEffect, useState } from 'react';
import { format } from 'library/util/format';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
import Services from 'services';
export const Info = (props) => {
    const [orderInfo, setOrderInfo] = useState(null);
    const [travellers, setTravellers] = useState([]);
    const [contactName, setContactName] = useState('');
    const [contactMobile, setContactMobile] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [comment, setComment] = useState('');
    const [jump, setJump] = useState(true);
    const location = useLocation();
    const history = useHistory();


    const submit = async () => {
        if (!contactName) {
            Actions.alert.show('请填写联系人姓名');
            return;
        }
        if (!contactMobile) {
            Actions.alert.show('请填写联系人手机号');
            return;
        }
        const result = await Services.order.create({
            audit: !jump ? travellers : [],
            productId: orderInfo.product.id,
            skuId: orderInfo.thisPrice.product_sku_id,
            travelDate: orderInfo.thisPrice.travel_at,
            attach: {
                contact_name: contactName,
                contact_mobile: contactMobile,
                contact_email: contactEmail,
                comment: comment
            }
        });
        if (result.status > 0) {
            history.replace(`/weixin/order/detail/${result.data.order.id}`);
        }
    }
    useEffect(() => {
        const { state } = location;
        const { product, thisPrice, child, audit, sku } = state;
        if (!product) {
            history.goBack();
            return;
        }
        setOrderInfo({ product, thisPrice, child, audit, sku });
    }, [location]);
    if (orderInfo) {
        const { product, sku, thisPrice, child, audit } = orderInfo;
        return <div className={styles.container}>
            <section>
                <h1>{product.title}</h1>
                <p>
                    <label>产品类型</label>
                    <span>{sku.name}</span>
                </p>
                <p>
                    <label>使用日期</label>
                    <span>{thisPrice.travel_at} 星期{['日', '一', '二', '三', '四', '五', '六'][moment(thisPrice.travel_at).weekday()]}</span>
                </p>
                <p>
                    <label>购买数量</label>
                    <span>成人x{audit} </span>
                    {child ? <span> 儿童x{child}</span> : false}
                </p>
            </section>
            <section>
                <div className={styles.sectionTitle}>
                    <div className={styles.left}>
                        出行信息
                    </div>
                    <div className={styles.right} onClick={() => {
                        setJump(!jump);
                    }}>
                        <i className={`${jump ? styles.checked : ''} icon iconfont icon-zhengqueshixin`}></i>确定付款时填写出行信息
                    </div>
                </div>
                {!jump ?
                    <>
                        {travellers.map((item, key) => {
                            return <div key={key} className={styles.line}>
                                <label>{item.name}</label>
                                <div className={styles.right}>
                                    <span>{item.sex > 0 ? '男' : '女'} {format.idCard(item.idcard)}</span>

                                    <button onClick={() => {
                                        setTravellers(travellers.filter((e, f) => f !== key));
                                    }}><i className='icon iconfont icon-shibai2'></i> 删除</button>

                                </div>
                            </div>;
                        })}

                        <div className={styles.line}>
                            <button className={styles.addTraveller} onClick={async () => {
                                const result = await Actions.travelModal.show().catch(e => false);
                                if (result) {
                                    setTravellers(travellers.concat([result]));
                                }
                                console.log(result);
                            }}>
                                <i className='icon iconfont icon-wode'></i>    添加出行人
                </button>
                        </div>
                    </> : false}
            </section>

            <section>
                <div className={styles.sectionTitle}>
                    <div className={styles.left}>
                        联系人信息
                </div>
                </div>
                <div className={styles.line}>
                    <label>姓名 <span className={styles.red}>*</span></label>
                    <div className={styles.right}>
                        <input value={contactName} onChange={(event) => {
                            setContactName(event.target.value);
                        }} type='text' placeholder='联系人姓名' />
                    </div>
                </div>
                <div className={styles.line}>
                    <label>手机号 <span className={styles.red}>*</span></label>
                    <div className={styles.right}>
                        <input value={contactMobile} onChange={(event) => {
                            setContactMobile(event.target.value);
                        }} type='text' placeholder='填写手机号' />
                    </div>
                </div>
                <div className={styles.line}>
                    <label>邮箱</label>
                    <div className={styles.right}>
                        <input value={contactEmail} onChange={(event) => {
                            setContactEmail(event.target.value);
                        }} type='text' placeholder='填写邮箱' />
                    </div>
                </div>

            </section>
            <section>
                <div className={styles.sectionTitle}>
                    备注
            </div>
                <textarea value={comment} onChange={(event) => {
                    setComment(event.target.value);
                }} rows='6' placeholder='备注内容'></textarea>
            </section>
            <FooterRender {...orderInfo} submit={submit} />
        </div>
    } else {
        return false;
    }
}

const DetailModal = (props) => {
    const { visible, child, audit, thisPrice, setDeteilModalVisible } = props;
    if (visible && thisPrice) {
        const auditPrice = parseFloat(audit * thisPrice.sale_price);
        const childPrice = parseFloat(child * thisPrice.child_sale_price);
        return <div className={styles.modal} onClick={() => {
            setDeteilModalVisible(false);
        }}>
            <div className={styles.modalBack}></div>
            <div className={styles.modalTitle}>
                <div className={styles.left}></div>
                <div className={styles.middle}>费用明细</div>
                <div className={styles.right}>
                    <i className='iconfont icon-shibai2'></i>
                </div>
            </div>
            <div className={styles.modalContent}>
                <div className={styles.modalContentTitle}>
                    <div className={styles.left}>基本费用</div>
                    <div className={styles.right}>¥{auditPrice + childPrice}</div>
                </div>
                <div className={styles.modalContentSubTitle}>
                    <div className={styles.left}>成人</div>
                    <div className={styles.right}>¥{thisPrice.sale_price}*{audit}</div>
                </div>
                <div className={styles.modalContentSubTitle}>
                    <div className={styles.left}>儿童</div>
                    <div className={styles.right}>¥{thisPrice.child_sale_price}*{child}</div>
                </div>
                <div className={styles.total}>
                    <strong>订单总额（在线支付）：</strong>
                    <em>¥{auditPrice + childPrice}</em>
                </div>
            </div>
        </div>
    } else {
        return false;
    }

}

const FooterRender = (props) => {
    const { submit, product, sku, child, audit, thisPrice, setDeteilModalVisible } = props;
    const total = parseFloat(audit * thisPrice.sale_price) + parseFloat(child * thisPrice.child_sale_price);
    return <div className={styles.footer}>
        <span className={styles.money}><small>¥</small> {total}
            <a className={styles.detail}>明细</a>
        </span>
        <button className={styles.share}><i className="icon iconfont icon-kefu"></i>客服</button>
        <button onClick={submit} className={styles.buy}>提交订单</button>
    </div>
}