import { ADD_FAV, REMOVE_FAV } from '../../constants';

export const product = {
    addFav: (id) => {
        return { type: ADD_FAV, data: id };
    },
    removeFav: (id) => {
        return { type: REMOVE_FAV, data: id };
    },
};
