import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Services from 'services';
import moment from 'moment';
import styles from './index.module.scss';
export const Coupon = (props) => {
    const [type, setType] = useState(0);
    const [offset, setOffset] = useState(0);
    const [list, setList] = useState([]);
    const [count, setCount] = useState(0);
    const history = useHistory();
    const limit = 10;

    const getCouponList = async (refresh) => {
        let _offset = offset;
        if (refresh) {
            _offset = 0;
        }
        const result = await Services.user.coupons({ type, offset: _offset, limit });
        if (result.status > 0) {
            setList((refresh ? [] : list).concat(result.data.rows));
            if (type === 0) {
                setCount(result.data.count);
            }
        }
    }

    useEffect(() => {
        getCouponList();
    }, []);

    useEffect(() => {
        getCouponList(true);
    }, [type]);


    return <div className={styles.container}>
        <div className={styles.head}>
            <ul>
                <li onClick={setType.bind(this, 0)} className={type === 0 ? styles.active : ''}>可使用 <small>({count})</small></li>
                <li onClick={setType.bind(this, 1)} className={type === 1 ? styles.active : ''}>已使用 </li>
                <li onClick={setType.bind(this, 2)} className={type === 2 ? styles.active : ''}>已过期 </li>
            </ul>
        </div>
        <div className={styles.list}>
            {list.map((item, key) => {
                const isExpired = moment(item.expired_at).diff(moment()) < 0;
                const isDisabled = isExpired || item.status === 0;
                return <div className={`${styles.coupon} ${isDisabled ? styles.expired : ''}`}>
                    <div className={styles.wrap}>
                        <div className={styles.left}>
                            <h2>{item.name}</h2>
                            <p>有效期至{moment(item.expired_at).format('YYYY.MM.DD')}</p>
                        </div>
                        <div className={styles.right}>
                            <h3><em>¥</em>{item.value}</h3>
                            <p>无门槛</p>
                        </div>
                    </div>
                    <div className={styles.split}></div>
                    <div className={styles.footer}>
                        <p>可与其他活动优惠同时享受。在线支付专项</p>
                        {isDisabled ? false : <button onClick={() => {
                            history.push('/weixin/home');
                        }}>立即使用</button>}
                    </div>
                </div>;
            })}

        </div>
    </div>
}