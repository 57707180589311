import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { Actions } from 'reduxs';

const navMap = [{
    title: '首页',
    url: '/weixin/home',
    icon: 'icon-jingdian1',
}, {
    title: '定制',
    url: '/weixin/special',
    icon: 'icon-dangdiwanle1',
}, {
    title: '旅拍',
    url: '/weixin/user/publish',
    icon: 'icon-paizhao',
}, {
    title: '客服',
    url: '',
    action: Actions.kf.show,
    icon: 'icon-kefu'
}, {
    title: '我的',
    url: '/weixin/user/center',
    icon: 'icon-wode'
}]
export default (props) => {
    const { index = 0 } = props;
    return <div className={styles.tab}>
        <ul>
            {navMap.map((item, key) => {
                return <li key={key} className={key === index ? styles.active : ''}>
                    {item.action ? <div onClick={() => { item.action() }}>
                        <i className={'icon iconfont ' + item.icon}></i>
                        <p>{item.title}</p>
                    </div> : <Link to={item.url}>
                            <i className={'icon iconfont ' + item.icon}></i>
                            <p>{item.title}</p>
                        </Link>}
                </li>
            })}

        </ul>
    </div>
}