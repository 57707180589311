import React from 'react';
import { Link } from 'react-router-dom';
import styles from './item.module.scss';
export default (props) => {
    const { className, to, ...item } = props;
    return <Link to={to || `/weixin/product/${item.id}`} className={`${styles.wrap} ${className ? className : ''}`}>
        <div className={styles.thumbnail}>
            <img src={item.banner} alt='banner' />
            {item.customer ? <div className={styles.departure}>
                <p>案例客户：{item.customer}</p>
                <p>出行人数：{item.customer_count}人</p>
            </div> : <div className={styles.departure}>
                    <i className='icon iconfont icon-dibiao '></i>
                    {item.departure}
                </div>}

        </div>
        <div className={styles.right}>
            <h4>{item.title} </h4>
            <p>
                <span>天数:<strong>{item.days}</strong></span>
                <span>推荐指数:<strong>{parseFloat(item.total_score).toPrecision()}</strong></span>
            </p>
            <p className={styles.date}>
                {item.current_travel_date && item.current_travel_date.split(',').map((item2, key2) => {
                    return <label key={key2}>{item2}</label>
                })}
            </p>
            <div className={styles.foot}>
                <label><span>¥ <strong>{item.sale_price}</strong></span> 起</label>
            </div>
        </div>


    </Link>
}
