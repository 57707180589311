import {
    USER_INVITER_ID,
    USER_OPEN_ID,
    USER_BASE_INFO,
    USER_UNION_ID,
    IS_STAFF,
    STAFF_MENU,
    USER_ARTICLE_LIKE,
    USER_ARTICLE_DISLIKE
} from '../../constants';
import * as Immutable from 'immutable';
const params = {
    openId: null,
    inviterId: null,
    baseInfo: null,
    unionId: null,
    isStaff: null,
    staffMenu: [],
    likeArticles: []
};
if (process.env.NODE_ENV === 'development') {
    params.openId = 'ooYKLwhj1VKpDodSuu9wFyz5gdAE';
    // params.openId = 'osEDb1XYAT37ARpg5eQ2HNovgRiQ';
}
const initialState = Immutable.fromJS(params);

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function user(state = initialState, action) {
    let likeArticles;
    switch (action.type) {
        case USER_INVITER_ID:
            return state.merge({ inviterId: action.data });
        case USER_OPEN_ID:
            return state.merge({ openId: action.data });
        case USER_BASE_INFO:
            return state.merge({ baseInfo: action.data });
        case USER_UNION_ID:
            return state.merge({ unionId: action.data });
        case IS_STAFF:
            return state.merge({ isStaff: action.data });
        case STAFF_MENU:
            return state.set('staffMenu', action.data || []);
        case USER_ARTICLE_LIKE:
            likeArticles = state.get('likeArticles') || [];
            likeArticles = likeArticles.push(action.data);
            console.log(action.data, likeArticles);

            return state.set('likeArticles', likeArticles);
        case USER_ARTICLE_DISLIKE:
            likeArticles = state.get('likeArticles') || [];
            likeArticles = likeArticles.filter(e => e !== action.data);
            likeArticles[action.data.key] = action.data.traveller;
            return state.set('likeArticles', likeArticles);
        default:
            return state;
    }
}
