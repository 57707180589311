import 'whatwg-fetch';
console.log(process.env.NODE_ENV);
let domain = '';

export default class Fetch {
    setHeaders(data, method) {
        data = data || {};
        data.headers = {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };
        return data;
    }

    param(obj, traditional) {
        var params = [];
        params.add = function(k, v) {
            this.push(escape(k) + '=' + encodeURI(v));
        };
        this.serialize(params, obj);
        return params.join('&').replace(/%20/g, '+');
    }

    serialize(params, obj, traditional, scope) {

        /**
         *
         * @param obj
         * @returns {boolean}
         */
        function isPlainObject(obj) {
            return typeof obj === 'object' && obj !== null && !(obj !== null && obj === obj.window);
        }

        let type;
        let array = Array.isArray(obj);
        let hash = isPlainObject(obj);
        let that = this;
        if (hash) {
            Object.keys(obj).forEach(function(key) {
                var value = obj[key];
                type = typeof value;
                if (scope) {
                    key = traditional
                        ? scope
                        : scope + '[' + (
                            hash || type === 'object' || type === 'array'
                                ? key
                                : '') + ']';
                }
                // handle data in serializeArray() format
                if (!scope && array) {
                    that.serialize(params, value, traditional, key);
                    params.add(value.name, value.value);
                } else if (type === 'array' || !traditional && type === 'object') {
                    that.serialize(params, value, traditional, key);
                } else {
                    params.add(key, value);
                }
            });
        }
    }

    beforeFetch() {
        console.log('start loading');
    }

    afterFetch() {
        console.log('end loading');
    }

    async doFetch(url, data, method) {
        data.method = method;
        data.credentials = 'include';
        return await fetch(domain + url, data).then(async (response) => {
            if (response.status.toString() === '200') {
                return response.json();
            } if (response.status.toString() === '302') {
                return { status: -999, data: await response.json() };
            } else {
                return { ...(await response.json()), status: - 1 };
            }
        }, (error) => {
            if (error) {
                if (error.toString() === 'TypeError: Failed to fetch' || error.toString().indexOf('取消') > -1) {
                    return { status: -1000, message: error || '网络连接失败' };
                }
                return { status: -2, message: error.toString() || '网络连接失败' };
            }
        });

    }

    async get(url, params, headers) {
        url = url + '?' + this.param(params);
        const data = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                ...headers
            }
        };
        return await this.doFetch(url, data, 'GET');
    }

    async post(url, data, headers) {
        data = {
            body: JSON.stringify(data)
        };
        data.headers = {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        };
        return await this.doFetch(url, data, 'POST');
    }

    async put(url, data, headers) {
        data = {
            body: JSON.stringify(data)
        };
        data = this.setHeaders(data);
        data.headers = {
            'Content-Type': 'application/json',
            ...headers,
        };
        return await this.doFetch(url, data, 'PUT');
    }

}
