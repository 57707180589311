import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import ProductItem from 'components/product/item';
import Services from 'services';
import { Actions } from 'reduxs';
import { useSelector } from 'react-redux';

const filterCities = ['不限', '上海', '杭州', '南京', '大连', '北京'];
const filterStartCities = ['不限', '上海', '北京', '天津'];
const filterDays = ['不限', '1日', '2日', '3日', '4日', '5日', '6日', '更多'];
const filterPrices = ['不限', '2千内', '2001-5000', '5千-1万', '1万-2万', '2万以上'];
const filterAttibute = ['不限', '跟团游', '定制游', '亲子游', '当地游', '自由行', '自驾车', '户外游', '单项目'];

const orders = ['综合评分由低到高', '关注度由高到低', '关注度由低到高', '价格由低到高', '价格由高到低', '销量由高到低', '销量由低到高']
export const FavProducts = () => {
    const [products, setProducts] = useState([]);
    const [y, setY] = useState(0);
    const scroller = useRef();

    const favProducts = useSelector((state) => {
        return state.product.toJS().favProducts || [];
    });

    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    const search = async (refresh) => {
        console.log(favProducts);
        const params = {};
        if (favProducts.length > 0) {
            params.productIds = favProducts;
        }
        if (page) {
            params.page = page * 10;
        }
        if (!refresh && totalPage < page + 1) {
            return;
        }
        const result = await Services.user.favProducts(params);
        if (result.status > 0) {
            if (refresh) {
                setProducts(result.data.productList);
                setTotalPage(Math.ceil(result.data.count / 10));
                setPage(0);
            } else {
                setProducts(products.concat(result.data.productList));
                setPage(page + 1);
            }
        }
    }

    useEffect(() => {
        search(true);
        if (scroller.current) {
            scroller.current.search = search;
            scroller.current.onScroll = (e) => {
                setY({
                    y: e.target.scrollTop,
                    offset: e.target.offsetHeight + 20 + e.target.scrollTop > e.target.scrollHeight
                });
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);


    useEffect(() => {
        if (y.offset) {
            search();
        }
    }, [y])


    return <div ref={scroller} className={`${styles.search}`}>
        <div className={styles.head}>
            我的收藏
        </div>
        <div className={styles.productList}>
            {products.map((item, key) => {
                return <ProductItem className={styles.product} key={key} {...item} />;
            })}
        </div>

    </div >
}