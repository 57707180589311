import {
    MODAL_SHOW, MODAL_HIDE,
    TRAVEL_MODAL_SHOW, TRAVEL_MODAL_HIDE,
    KF_MODAL_SHOW, KF_MODAL_HIDE,
} from '../../../constants';

export const kf = {
    show: () => {
        return { type: KF_MODAL_SHOW };
    },
    hide: () => {
        return { type: KF_MODAL_HIDE };
    }
};

export const modal = {
    show: (content, title, buttons) => {
        return {
            type: MODAL_SHOW,
            data: {
                content,
                title,
                buttons
            }
        };
    },
    hide: () => {
        return { type: MODAL_HIDE };
    }
};

export const travelModal = {
    show: () => {
        return async (dispatch) => {
            return await new Promise((success, error) => {
                dispatch(
                    {
                        type: TRAVEL_MODAL_SHOW,
                        data: {
                            success,
                            error
                        }
                    }
                );
            });
        };
    },
    hide: () => {
        return { type: TRAVEL_MODAL_HIDE }
    }
}