import React, { useEffect, useState } from 'react';
import Services from 'services';
import { Link, useParams } from 'react-router-dom';
import styles from './index.module.scss';
export const List = (props) => {
    const [list, setList] = useState([]);

    const getList = async () => {
        const result = await Services.article.list();
        if (result.status > 0) {
            setList(result.data.companyArticles);
        }
    }

    useEffect(() => {
        getList();
    }, []);
    return <div className={styles.list}>
        {list.map((item, key) => {
            return <Link key={key} to={`/weixin/article/detail/${item.id}`} className={styles.line}>
                <div className={styles.left}>
                    {item.title}
                </div>
                <div className={styles.right}>
                    {item.created_at}
                </div>
            </Link>
        })}
    </div>
}