import { ADD_TRAVELLER, EDIT_TRAVELLER, DEL_TRAVELLER } from '../../constants';

export const travel = {
    addTraveller: (traveller) => {
        return { type: ADD_TRAVELLER, data: traveller };
    },
    editTraveller: (key, traveller) => {
        return { type: EDIT_TRAVELLER, data: { key, traveller } };
    },
    removeTraveller: (key) => {
        return { type: DEL_TRAVELLER, data: key };
    }
};
