import React, { useState, useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import styles from './index.module.scss';
import Services from 'services';
import { Link, useHistory } from 'react-router-dom';
import ProductItem from 'components/product/item';
import Tab from 'components/tab';

const iconMap = ['icon-jingdianxiao', 'icon-jichangxiao', 'icon-ditiexiao1', 'icon-gouwu', 'icon-mudedi1', 'icon-huochezhanxiao', 'icon-dangdiwanle', 'icon-daohang'];
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default (props) => {
    const [pageInfo, setPageInfo] = useState();
    const scroller = useRef();
    const [y, setY] = useState(0);

    const [tehui, setTehui] = useState([]);
    useEffect(() => {
        getHomePage();
    }, []);
    useEffect(() => {
        if (scroller.current) {
            scroller.current.onScroll = (e) => {
                // console.log(e);
                setY(e.target.scrollTop);
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);
    const getHomePage = async () => {
        const result = await Services.page.home();
        if (result) {
            setPageInfo(result.data);
            console.log(result.data && result.data.banner);
            window.wx.ready(() => {
                let banners = result.data && result.data.banner;
                const params = {
                    title: '上海金中旅官方网站.旅游.团建.定制', // 分享标题
                    desc: '金中旅游.出行省心.玩得尽兴.安全保障', // 分享描述
                    link: window.location.href, // 分享链接
                    imgUrl: banners.length > 0 ? window.location.protocol + banners[0].value : false, // 分享图标
                    success: () => {
                    }
                };
                window.wx.updateTimelineShareData(params);
                window.wx.updateAppMessageShareData(params);
            });
        }
    }


    return <div ref={scroller} className={styles.home}>
        {pageInfo ? <>
            <HeaderRender y={y} />
            <BannerRender banners={pageInfo && pageInfo.banner} />
            <NavRender list={pageInfo && pageInfo.menuList} />
            <HotRender products={pageInfo && pageInfo.serializeProducts[129]} />
            <ProductListRender targets={pageInfo.targets} products={pageInfo && pageInfo.serializeProducts} />
        </> : false}
        <Tab />
    </div>;
}

const ProductListRender = (props) => {
    const { products } = props;
    const [parentIndex, setParentIndex] = useState(0);
    const [parents, setParents] = useState([]);
    const [children, setChilren] = useState([]);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [selectChild, setSelectChild] = useState(null);
    const history = useHistory();
    useEffect(() => {
        setParents(props.targets.filter(e => e.status > 0 && e.home_status > 0 && !e.parent_id));
    }, [props.targets]);
    const selectedParent = parents[parentIndex];

    useEffect(() => {
        if (selectedParent) {
            setChilren(props.targets.filter(e => e.status > 0 && e.home_status > 0 && e.parent_id === selectedParent.id));
        }
    }, [selectedParent]);

    useEffect(() => {

    }, [selectChild]);
    const parentTargetId = children[childrenIndex] && children[childrenIndex].id;
    return <section className={styles.section}>
        <div className={styles.sectionHeader}><strong>推荐线路</strong></div>
        <div className={styles.target}>
            <ul className={styles.parent}>
                {parents.map((item, key) => {
                    return <li className={key === parentIndex ? styles.active : ''} onClick={() => {
                        setParentIndex(key);
                        setChildrenIndex(0);
                    }} key={key}><p>{item.name}</p>
                        <p className={key === parentIndex ? styles.activeBottom : styles.bottom}></p></li>;
                })}

            </ul>
            <ul className={styles.children}>
                {children.map((item, key) => {
                    return <li onClick={() => {
                        setChildrenIndex(key);
                    }} key={key} className={childrenIndex === key ? styles.active : ''}>
                        {item.name}
                    </li>;
                })}
            </ul>
        </div>
        {(products[parentTargetId] || []).map((item, key) => {
            if (key <= 10) {
                return <ProductItem key={key} {...item} />;
            } else {
                return false;
            }
        })}
        {selectedParent ? <div className={styles.toTarget} onClick={() => {
            history.push('/weixin/target/' + selectedParent.id);
        }}>
            获取更多{selectedParent.name}产品信息
        </div> : false}

    </section>
}

const HotRender = (props) => {
    const { products } = props;
    if (products) {
        return <section className={styles.section}>
            <div className={styles.sectionHeader}><strong>特惠专区</strong></div>
            {products.map((item, key) => {
                return <ProductItem to={'/weixin/home/product/' + item.id} key={key} {...item} />;
            })}
        </section>
    } else {
        return false;
    }
}
const HeaderRender = (props) => {
    const { y } = props;
    const rate = y / 40;
    return <div className={styles.header} style={{ background: `rgba(255,255,255,${rate})` }}>
        <Link to='/weixin/search' className={styles.searchBox} style={{ color: rate < 0.2 ? `rgba(255,255,255,${rate || 1})` : `rgba(0,0,0,${rate || 1})` }}>
            <i className="icon iconfont icon-sousuo" ></i>
            <span>搜索目的地/关键词</span>
        </Link>
    </div>;
}
const BannerRender = (props) => {
    const { banners = [] } = props;
    const [thisIndex, setThisIndex] = useState(0);
    return <div className={styles.bannerWrap}>
        <AutoPlaySwipeableViews autoplay interval={5000} resistance={true} onChangeIndex={(index) => {
            setThisIndex(index);
        }} className={styles.banner}>
            {banners.map((item, key) => {
                return <div className={styles.swiper} key={key} onClick={() => {
                    if (item.link) { window.location.href = item.link; }
                }}>
                    {item.type === 'image' ? <img src={item.value} width="100%" alt="" /> : <video preload controls src={item.value} width='100%' />}
                </div>
            })}
        </AutoPlaySwipeableViews>
        <div className={styles.pagination}>
            {banners.map((item, key) => {
                return <span key={key} className={thisIndex === key ? styles.active : {}}></span>
            })}
        </div>
    </div>;
}

const NavRender = (props) => {
    const { list } = props;
    const history = useHistory();
    return <div className={styles.nav}>
        <ul>
            {list.map((item, key) => {
                return <li key={key} onClick={() => {
                    history.push(item.link);
                }}>
                    <i className={`${styles.menuIcon} icon iconfont ${iconMap[key % 8]}`}></i>
                    <p>{item.value}</p>
                </li>;
            })}
        </ul>
    </div>;
}