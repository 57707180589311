import Library from 'library';
import * as resources from './resources';
import { Actions } from 'reduxs';

const { Fetch, Platform, JsBridge } = Library;
const sourceMap = {
    'Wechat': 'wh5',
    'Wap': 'h5',
    'GuApp': 'App'
};
export const combineResource = (actions) => {
    const services = {};

    Object.keys(resources).forEach((key, i) => {
        const obj = {};
        const resource = resources[key];
        Object.keys(resource).forEach((_key, _i) => {
            obj[_key] = async (params = {}) => {
                const item = resource[_key];

                if (Platform.name() === 'GuApp') {
                    return await appService(item, params);
                } else {
                    return await h5Service(item, params);
                }

            };
        });
        services[key] = obj;
    });
    return services;
};

const appService = async (item, params) => {
    if (item.appService) {
        const [module, serviceName] = item.appService;
        const result = await JsBridge.runServices(module, serviceName, params ? [params] : [], !item.customLoading);
        if (result.status > 0) {
            return result;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

const h5Service = async (item, params) => {
    const url = typeof item === 'string'
        ? item
        : item.url || '';
    const method = typeof item === 'string'
        ? 'post'
        : item.method || 'post';

    if (!item.customLoading) {
        Actions.loading.start();
    }
    const res = await new Fetch()[method](url, params, { source: sourceMap[Platform.name()] });
    if (!item.customLoading) {
        Actions.loading.end();
    }
    if (res.status > 0) {
        return res;
    } else if (res.status === -999) {
        window.location.href = res.data.redirectUrl;
        return false;
    } else if (res.status === -1000) {
        return false;
    } else {
        console.log(res);
        Actions.alert.show(res.message || '网络请求异常!');
        return false;
    }
}

export default combineResource(Actions);
