import { ADD_TRAVELLER, EDIT_TRAVELLER, DEL_TRAVELLER } from '../../constants';
import * as Immutable from 'immutable';
const params = {
    travellers: []
};
const initialState = Immutable.fromJS(params);

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function travel(state = initialState, action) {
    let travellers;
    switch (action.type) {
        case ADD_TRAVELLER:
            travellers = state.get('travellers');
            travellers = travellers.push(action.data);
            return state.set('travellers', travellers);
        case EDIT_TRAVELLER:
            travellers = state.get('travellers');
            travellers[action.data.key] = action.data.traveller;
            return state.set('travellers', travellers);
        case DEL_TRAVELLER:
            travellers = state.get('travellers');
            travellers = travellers.splice(action.data, 1)
            return state.set('travellers', travellers);
        default:
            return state;
    }
}
