import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
const ModalComponent = (props) => {
    const { title, status, content, buttons } = props.modal;

    const displayButtons = () => {
        if (!buttons) {
            return [{
                name: '确定', click: () => {
                    Actions.modal.hide();
                },
                className: styles.full,
            }]
        }
        if (buttons.length <= 1) {
            return buttons.map((item) => {
                item.className = styles.full;
                return item;
            });
        } else {
            return buttons.map((item, key) => {
                if (key % 2 === 0) {
                    item.className = styles.light;
                } else {
                    item.className = styles.deep;
                }
                return item;
            });
        }
    }
    return <div className={`${styles.modal} ${status === 'SHOW' ? styles.show : styles.hide}`}>
        <div className={styles.modalWrap}>
            <div className={styles.modalTitle}>{title || '温馨提示'}</div>
            <div className={styles.modalContent}>{content || ''}</div>
            <div className={styles.modalButton}>
                {displayButtons().map((item, key) => {
                    return <button className={item.className} key={key} onClick={item.click}>{item.name}</button>;
                })}
            </div>
        </div>
    </div>
};

export const Modal = connect((state) => {
    return { modal: state.modal.toJS() };
})(ModalComponent);
