/**
 * Created by z-benjamin on 20/4/22.
 */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Library from 'library';

export const ChangeTitle = (props) => {
    const documentRef = useRef();

    const changeTitle = (title) => {
        window.document.title = title;
        const iframe = document.createElement('iframe');
        let style = { display: 'none' };
        for (let i in style) {
            iframe.style[i] = style[i];
        }
        iframe.stylesheet = '';
        iframe.src = '/favicon.ico';
        iframe.addEventListener('load', () => {
            iframe.removeEventListener('load', () => {
                //
            });
            iframe.remove();
        });
        documentRef && documentRef.current && documentRef.current.appendChild(iframe);
    }

    useEffect(() => {
        console.log(props.title);
        changeTitle(props.title);
    }, [props.title]);

    return (
        <>
            <div ref={documentRef} />
        </>
    );
}
export const HighOrderChangeTitle = (WrappedComponent) => {

    const HighOrderComponent = (props) => {
        const documentRef = useRef();

        const wxApiConfig = useSelector((state) => {
            return state.wxApi.toJS().config;
        });

        const changeTitle = (title) => {
            window.document.title = title;
            const iframe = document.createElement('iframe');
            let style = { display: 'none' };
            for (let i in style) {
                iframe.style[i] = style[i];
            }
            iframe.stylesheet = '';
            iframe.src = '/favicon.ico';
            iframe.addEventListener('load', () => {
                iframe.removeEventListener('load', () => {
                    //
                });
                iframe.remove();
            });
            documentRef && documentRef.current && documentRef.current.appendChild(iframe);
        }

        useEffect(() => {
            if (wxApiConfig) {
                changeTitle(props.title);
            }
        }, [props.title, wxApiConfig]);

        return (
            <>
                <div ref={documentRef} />
                <WrappedComponent {...props} />
            </>
        );
    }

    return HighOrderComponent;

}