import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Library from 'library';
import Services from 'services';
import { Actions } from 'reduxs';
import GUComponents from 'components';
import { useSelector } from 'react-redux';
export const Main = (props) => {
    const history = useHistory();

    const wxApiConfig = useSelector((state) => {
        return state.wxApi.toJS().config;
    });

    useEffect(() => {


        initJsBridge();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initJsBridge = async () => {
        jsApiOauth();
        history.listen(async (location) => {
            jsApiOauth();
        });
    }


    const jsApiOauth = async () => {
        if (Library.Platform.check.isWechat()) {
            Actions.wxApi.start();
            let signatureResult = await Services.weixin.jsSdkSignature({
                url: encodeURIComponent(window.signLink || window.location.href),
            });
            const result = signatureResult.data || {};
            let params = {};
            params.debug = false;
            params.appId = result.appId;
            params.nonceStr = result.nonceStr;
            params.signature = result.signature;
            params.timestamp = result.timestamp;
            params.jsApiList = [
                'updateTimelineShareData',
                'updateAppMessageShareData',
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'onMenuShareQQ',
                'onMenuShareWeibo',
                'onMenuShareQZone',
                'scanQRCode',
                'hideOptionMenu',
                'showOptionMenu',
                'getLocation',
                'chooseImage',
                'previewImage',
                'getLocalImgData',
                'showMenuItems',
                'hideMenuItems'
            ];
            window.wx.config(params);
            window.wx.ready(function() {
                Actions.wxApi.end();
            });

        } else {
            Actions.wxApi.start();
            Actions.wxApi.end();
        }
    };
    return <>
        {props.children}
        <GUComponents.Traveller />
        <GUComponents.KF />
        <GUComponents.Alert />
        <GUComponents.Modal />
        <GUComponents.Loading />

    </>;
};
