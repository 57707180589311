/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */
export const url = {
    param: (name) => {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        let r = window.location.search.substr(1).match(reg);
        if (r !== null) {
            return decodeURI(r[2]);
        }
        return null;
    },
    paramsToObj: () => {
        let obj = {};
        const params = window.location.search.replace('?', '').split('&');
        params.forEach((value, key) => {
            const thisKey = value.split('=')[0];
            const thisValue = value.split('=')[1];
            obj[thisKey] = thisValue
        });
        return obj;
    }
};

