import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';

const statusMap = ['全部', '审核', '确认中', '签订合同', '代付款', '完成', '退款中', '关闭', '无效'];
const statusIcon = ['icon-dingdanjihe', 'icon-shikebiao', 'icon-qitadingdan', 'icon-dingdan', 'icon-dingdandaifukuan', 'icon-dingdanyichenggong', 'icon-dingdanchulizhong', 'icon-tuichu', 'icon-xiangqing']
export const Orders = (props) => {
    const { paramStatus } = useParams();
    const [limit] = useState(10);
    const [status, setStatus] = useState(paramStatus || 0);
    let [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const history = useHistory();
    useEffect(() => {
        document.getElementById('root').addEventListener('scroll', onScroll);
        return () => {
            document.getElementById('root').removeEventListener('scroll', onScroll);
        }
    });
    useEffect(() => {
        getOrderList(true);
    }, [status]);

    const onScroll = (event) => {
        if (event.target.scrollTop + window.screen.height - 20 > event.target.offsetHeight) {
            getOrderList();
        }
    }

    const getOrderList = async (refresh) => {
        if (refresh) {
            list = [];
        }
        if (!hasMore && !refresh) {
            return;
        }
        const result = await Services.order.list({ offset: list.length, limit, status });
        if (result) {
            const newList = [].concat(list, result.data.rows)
            setList(newList);
            setHasMore(result.data.count > newList.length);
        }
    }

    return <div className={styles.container}>
        <section>
            <div className={styles.title}>
                <h2>我的订单</h2>
            </div>
            <div className={styles.order}>
                {statusMap.map((item, key) => {
                    return <div onClick={() => {
                        setStatus(key);
                    }} className={`${styles.orderWrap} ${key.toString() === status.toString() ? styles.active : ''}`}>
                        <div className={styles.orderItem}>
                            <i className={'icon iconfont ' + statusIcon[key]}></i>
                            <p>{item}</p>
                        </div>
                    </div>;
                })}
            </div>
        </section>
        {list.map((item, key) => {
            return <section onClick={() => {
                history.push('/weixin/order/detail/' + item.id);
            }} className={styles.orderList}>
                <div className={styles.title}>
                    <p>
                        订单号：{item.order_no}
                    </p>
                    <p>
                        {item.created_at}
                    </p>
                </div>
                <div className={`${styles.title} ${styles.orderTitle}`}>
                    <h2>{item.Product.title}</h2>
                </div>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <span>购买金额</span>
                        <span>成人 x {item.audit_amount} {item.child_amount ? `儿童 x ${item.child_amount}` : ''}</span>
                    </div>
                    <div className={styles.row}>
                        <b>订单金额</b>
                        <strong><small>¥</small>{item.confirm_price}</strong>
                    </div>
                </div>
                <div className={styles.footer}>
                    <span>{statusMap[item.status]}</span>
                    <button onClick={(event) => {
                        event.stopPropagation();
                        history.push('/weixin/order/detail/' + item.id);
                    }}>详情</button>
                </div>
            </section>
        })}
        <footer>
            {!hasMore && list.length > 0 ? <p>已经到底了</p> : false}
            {!hasMore && list.length <= 0 ? <p>暂无订单</p> : false}
        </footer>

    </div>
}