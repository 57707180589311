import React from 'react';
import { Provider } from 'react-redux';
import { Store, History } from 'reduxs';
// import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter as ConnectedRouter, } from 'react-router-dom';
import { RouteRender } from 'routes';
import './scss/index.scss';
import 'antd-mobile/dist/antd-mobile.css'; 

function App() {
  return <Provider store={Store}>
    <ConnectedRouter history={History}>
      <RouteRender />
    </ConnectedRouter>
  </Provider>;
}

export default App;
