import React from 'react';

import './rule.scss';

export class LottoryRule extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {

        };
    }

    render() {
        return <div className='integral-rule-wrap'>
            <p>
                一、活动期间，用户每天可获得1次抽奖机会，每次抽奖随机出奖；
            </p>
            <p>
                二、如出现不可抗力或情势变更的情况（包括重大灾害事件、活动受政府机关指令需要停止举办或调整的、活动遭受严重网络攻击或因系统故障等）导致活动需要停止或调整的，我们有权终止活动而无需向用户进行赔偿或补偿；
            </p>
            <p>
                三、在参与活动过程中，如用户出现作弊等违法违规行为我们有权撤销用户参与活动及奖励使用资格，并有权收回用户已经获得的权益；
            </p>
            <p>
                四、活动由金中旅提供，与设备生产商Apple Inc.公司无关。
            </p>
        </div>;
    }
}
