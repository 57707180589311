export const order = {
    create: {
        url: '/wxApi/order/create',
    },
    detail: {
        url: '/wxApi/order/detail',
        method: 'get'
    },
    update: {
        url: '/wxApi/order/update'
    },
    getWeixinPayUrl: {
        url: '/wxApi/order/getWeixinPayUrl'
    },
    getAlipayUrl: {
        url: '/wxApi/order/getAlipayUrl'
    },
    status: {
        url: '/wxApi/order/status',
        method: 'get'
    },
    list: {
        url: '/wxApi/user/order'
    },
    comment: {
        url: '/wxApi/order/commentOrder'
    }
}