import React, { useState, useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import styles from './index.module.scss';
import Services from 'services';
import { Link, useParams, useHistory } from 'react-router-dom';
import ProductItem from 'components/product/item';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default (props) => {
    const [pageInfo, setPageInfo] = useState();
    const scroller = useRef();
    const [y, setY] = useState(0);
    const { targetId } = useParams();

    useEffect(() => {
        getHomePage();
    }, []);
    useEffect(() => {
        if (scroller.current) {
            scroller.current.onScroll = (e) => {
                // console.log(e);
                setY(e.target.scrollTop);
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);
    const getHomePage = async () => {
        const result = await Services.page.target({ id: targetId });
        if (result) {
            setPageInfo(result.data);
        }
    }


    return <div ref={scroller} className={styles.home}>
        {pageInfo ? <>
            <HeaderRender y={y} />
            <BannerRender banners={pageInfo && pageInfo.banners} />
            <ProductListRender subTargets={pageInfo.subTargets} secondTargets={pageInfo.secondTargets.filter(e => e.status > 0)} products={pageInfo && pageInfo.products} />
        </> : false}
    </div>;
}

const ProductListRender = (props) => {
    const { products, secondTargets: parents, subTargets } = props;
    const [parentIndex, setParentIndex] = useState(0);
    const [children, setChilren] = useState([]);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [selectChild, setSelectChild] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const selectedParent = parents[parentIndex];
        if (selectedParent) {
            setChilren(props.subTargets.filter(e => e.status > 0 && e.parent_id === selectedParent.id));
        }
    }, [parents, parentIndex]);
    const parentTarget = parents[parentIndex];
    const parentTargetId = parents[parentIndex] && parents[parentIndex].id;
    return <section className={styles.section}>
        <div className={styles.sectionHeader}><strong>推荐线路</strong></div>
        <div className={styles.target}>
            <ul className={styles.parent}>
                {parents.filter(e => e.status > 0).map((item, key) => {
                    return <li className={key === parentIndex ? styles.active : ''} onClick={() => {
                        setParentIndex(key);
                        setChildrenIndex(0);
                    }} key={key}><p>{item.name}</p>
                        <p className={key === parentIndex ? styles.activeBottom : styles.bottom}></p></li>;
                })}

            </ul>
            <ul className={styles.children}>
                {children.map((item, key) => {
                    return <li onClick={() => {
                        history.push('/weixin/search/' + item.name);
                    }} key={key}>
                        {item.name}
                    </li>;
                })}
            </ul>
        </div>
        {(products[parentTargetId] || []).map((item, key) => {
            if (key <= 10) {
                return <ProductItem key={key} {...item} />;
            } else {
                return false;
            }
        })}
        {parentTargetId ? <div className={styles.toTarget} onClick={() => {
            history.push('/weixin/search/' + parentTarget.name);
        }}>
            获取更多产品信息
        </div> : false}
    </section>
}

const HotRender = (props) => {
    const { products } = props;
    if (products) {
        return <section className={styles.section}>
            <div className={styles.sectionHeader}><strong>特惠专区</strong></div>
            {products.map((item, key) => {
                return <ProductItem key={key} {...item} />;
            })}
        </section>
    } else {
        return false;
    }
}
const HeaderRender = (props) => {
    const { y } = props;
    const history = useHistory();
    const rate = y / 40;
    return <div className={styles.header} style={{ background: `rgba(255,255,255,${rate})` }}>
        <div onClick={() => {
            history.replace('/weixin/home');
        }} className={styles.headerBack}><i style={{ color: rate < 0.2 ? `rgba(255,255,255,${rate || 1})` : `rgba(0,0,0,${rate || 1})` }} className='icon iconfont icon-fanhuijiantou'></i></div>
        <Link to='/weixin/search' className={styles.searchBox} style={{ color: `rgba(255,255,255,${rate || 1})` }}>
            <i className="icon iconfont icon-sousuo" ></i>
            <span>搜索目的地/关键词</span>
        </Link>
        <div className={styles.headerBack}></div>
    </div>;
}
const BannerRender = (props) => {
    const { banners = [] } = props;
    const [thisIndex, setThisIndex] = useState(0);
    return <div className={styles.bannerWrap}>
        <AutoPlaySwipeableViews autoplay interval={5000} resistance={true} onChangeIndex={(index) => {
            setThisIndex(index);
        }} className={styles.banner}>
            {banners.map((item, key) => {
                return <div className={styles.swiper} key={key} onClick={() => {
                    if (item.link) { window.location.href = item.link; }
                }}>
                    {item.type === 'image' ? <img src={item.value} width="100%" alt="" /> : <video preload controls src={item.value} width='100%' />}
                </div>
            })}
        </AutoPlaySwipeableViews>
        <div className={styles.pagination}>
            {banners.map((item, key) => {
                return <span className={thisIndex === key ? styles.active : {}}></span>
            })}
        </div>
    </div>;
}
