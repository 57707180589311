import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';
import { validate, format } from 'library/util';
import { Actions } from 'reduxs';
import { useSelector } from 'react-redux';

let timer;
export const Mobile = (props) => {
    const [mobile, setMobile] = useState('');
    const [code, setCode] = useState('');
    const [countDown, setCountDown] = useState(0);
    const expiredTime = useSelector((state) => {
        const loginCounter = state.sms.toJS().userLoginCounter;
        const expire = loginCounter && loginCounter.expire;
        return expire;
    });
    const dx = useRef();
    const mobileRef = useRef();
    const myCaptcha = useRef();
    const history = useHistory();
    useEffect(() => {
        mobileRef.current = mobile;
        if (!myCaptcha.current) {
            myCaptcha.current = window._dx.Captcha(dx.current, {
                appId: '241642731b31322b102cd3b093c73522', //appId，在控制台中“应用管理”或“应用配置”模块获取
                style: 'popup',
                success: function(token) {
                    Services.weixin.sms({ token, mobile: mobileRef.current }).then((result) => {
                        const newDate = new Date().getTime() + 60 * 1000;
                        Actions.sms.countDownLogin(newDate);
                        if (result) {
                            myCaptcha.current.hide();
                        }
                    });

                }
            });
        }
    }, [mobile]);

    useEffect(() => {
        clearInterval(timer);
        timer = setInterval(() => {
            setCountDown(Math.max(0, parseInt((expiredTime - new Date().getTime()) / 1000)));
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, [expiredTime]);

    const submit = async () => {
        const result = await Services.user.login({ mobile, code });
        if (result) {
            await Actions.alert.show(result.data);
            if (window.location.href === window.entrance) {
                history.push('/weixin/home');
            } else {
                history.goBack();
            }
            
        }
    };

    return <div className={styles.login}>
        <h1 className={styles.loginH1}>注册/登录</h1>
        <p className={styles.remind}>未注册手机号验证后自动创建</p>

        <div className={styles.input}>
            <input value={format.mobile(mobile, ' ')} onChange={(event) => {
                setMobile(event.target.value.replace(/\s/g, ''));
            }} type='tel' placeholder='请输入手机号' />
            {countDown > 0 ? <button disabled className={styles.sms}>{countDown} 秒</button> :
                <button onClick={() => {
                    if (validate.mobile(mobile)) {
                        myCaptcha.current.show();
                    } else {
                        Actions.alert.show('请输入正确的手机号');
                    }
                }} className={styles.sms}>获取</button>
            }
        </div>
        <div className={styles.verify} ref={dx}></div>

        <div className={`${styles.input} ${styles.code}`}>
            <input value={code} onChange={(event) => {
                setCode(event.target.value.replace(/\s/g, ''));
            }} type='tel' placeholder='请输入验证码' maxLength={6} />
        </div>


        <button className={styles.weixin} onClick={submit}>
            注册/登录
        </button>
    </div>
}