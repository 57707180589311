import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DatePicker } from 'antd-mobile';
import styles from './index.module.scss';
import { Actions } from 'reduxs';
import { format, validate } from 'library/util';

export const Traveller = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [traveller, setTraveller] = useState({ sex: '1', idtype: '居民身份证' });
    const modal = useSelector((state) => {
        return state.travelModal.toJS();
    });

    const travellers = useSelector((state) => {
        return state.travel.toJS().travellers || [];
    });

    const changeTraveller = (key, value) => {
        const _traveller = JSON.parse(JSON.stringify(traveller));
        _traveller[key] = value;
        setTraveller(_traveller);
    }

    const saveTraveller = () => {
        if (!traveller.name) {
            Actions.alert.show('请填写姓名');
            return;
        } if (!traveller.idcard) {
            Actions.alert.show('请填证件号');
            return;
        }
        if (traveller.idtype === '居民身份证' || traveller.idtype === '临时身份证') {
            const birthday = validate.idcard(traveller.idcard);
            if (!birthday) {
                Actions.alert.show('请填正确的证件号');
                return;
            }
            traveller.birthday = birthday;
        } else {
            if (!traveller.birthday) {
                Actions.alert.show('请填写出生日期');
                return;
            }
        }
        Actions.travel.addTraveller(traveller);
        setIsEdit(false);
        setTraveller({});
    }
    if (modal.status === 'SHOW') {
        return <div className={styles.travelModal}>
            {isEdit ? <><div className={styles.modalTitle}>
                <div className={styles.cancel} onClick={() => {
                    setIsEdit(false);
                }}>
                    取消
                </div>
                <div className={styles.title}>
                    新增出行人
                </div>
                <div className={styles.confirm} onClick={() => {
                    saveTraveller();
                }}>
                    保存
                </div>
            </div>
                <div className={styles.content}>
                    <div className={styles.line}>
                        <label>姓名 <span className={styles.red}>*</span></label>
                        <div className={styles.right}>
                            <input type='text' placeholder='联系人姓名' value={traveller.name || ''} onChange={(event) => {
                                changeTraveller('name', event.target.value);
                            }} />
                        </div>
                    </div>
                    <div className={styles.line}>
                        <label>性别 </label>
                        <div className={styles.right}>
                            <div className={styles.line}>
                                <label className={styles.control}><input onChange={(event) => {
                                    changeTraveller('sex', event.target.value);
                                }} checked={traveller.sex === '1'} type='radio' value='1' /> 男</label>
                                <label className={styles.control}><input onChange={(event) => {
                                    changeTraveller('sex', event.target.value);
                                }} checked={traveller.sex === '0'} type='radio' value='0' /> 女</label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.line}>
                        <label>证件类型</label>
                        <div className={styles.right}>
                            <select value={traveller.idtype || ''} onChange={(event) => {
                                changeTraveller('idtype', event.target.value);
                            }} placeholder='证件类型' >
                                <option value="">请选择证件类型</option>
                                <option value="居民身份证">居民身份证</option>
                                <option value="临时身份证">临时身份证</option>
                                <option value="护照">护照</option>
                                <option value="军官证">军官证</option>
                                <option value="士兵证">士兵证</option>
                                <option value="回乡证">回乡证</option>
                                <option value="台胞证">台胞证</option>
                                <option value="港澳通行证">港澳通行证</option>
                                <option value="其他">其他</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.line}>
                        <label>证件号 <span className={styles.red}>*</span></label>
                        <div className={styles.right} >
                            <input value={traveller.idcard || ''} onChange={(event) => {
                                changeTraveller('idcard', event.target.value);
                            }} type='text' placeholder='证件号' />
                        </div>
                    </div>
                    <div className={styles.line}>
                        <label>手机号 </label>
                        <div className={styles.right}>
                            <input onChange={(event) => {
                                changeTraveller('mobile', event.target.value);
                            }} type='text' placeholder='填写手机号' value={traveller.mobile || ''} />
                        </div>
                    </div>
                    {traveller.idtype !== '居民身份证' && traveller.idtype !== '临时身份证' ? <div className={styles.line}>
                        <label>出生日期 <span className={styles.red}>*</span></label>
                        <div className={styles.right}>
                            <DatePicker mode='date' minDate={new Date(1960, 1, 1)} format='YYYY-MM-DD' value={new Date(traveller.birthday || null)} onChange={(value) => {
                                changeTraveller('birthday', moment(value).format('YYYY-MM-DD'));
                            }} >
                                <Birthday />
                            </DatePicker>
                        </div>
                    </div> : false}

                </div></> : <> <div className={styles.modalTitle}>
                    <div className={styles.cancel} onClick={() => {
                        modal.error('cancel');
                        Actions.travelModal.hide();
                    }}>  取 消 </div>
                    <div className={styles.title}>
                        选择出行人
            </div>
                    <div className={styles.confirm} onClick={() => {
                        setIsEdit(true);
                    }}>
                        <i className='icon iconfont icon-wode'></i>    添加
            </div>
                </div>
                    <div className={styles.content}>
                        {travellers.map((item, key) => {
                            return <div key={key} onClick={() => {
                                modal.success(item);
                                Actions.travelModal.hide();
                            }} className={styles.line}>
                                <label>{item.name}
                                    {moment().diff(moment(item.birthday), 'year') < 12 ? '(儿童)' : ''}

                                </label>
                                <div className={styles.right} >
                                    {item.sex > 0 ? '男' : '女'}

                                    {format.idCard(item.idcard)}
                                    <button onClick={(event) => {
                                        event.stopPropagation();
                                        Actions.travel.removeTraveller(key);
                                    }}><i className='icon iconfont icon-shibai2'></i> 删除</button>

                                </div>
                            </div>;
                        })}
                    </div>
                </>
            }

        </div >;
    } else {
        return false;
    }
}

const Birthday = ({ extra, onClick, children }) => (
    <input readOnly onClick={onClick} type='text' placeholder='选择出生日期' value={extra} />
);