/**
*读取图片
*/
export function loadImage(img) {
    return new Promise((resolve, reject) => {
        if (img.complete) {
            resolve(img);
        } else {
            img.onload = () => {
                resolve(img);
            };
        }
        img.onerror = img.onabort = () => {
            resolve(null);
        };
    });
}
