import { ALERT_SHOW, ALERT_HIDE } from '../../../constants';
import * as Immutable from 'immutable';

const initialState = Immutable.fromJS({ status: 'HIDE', title: '', messages: [] });

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function alert(state = initialState, action) {
    switch (action.type) {
        case ALERT_SHOW:
            action.data.status = 'SHOW';
            return state.merge(action.data);
        case ALERT_HIDE:
            return state.merge({ status: 'HIDE'});
        default:
            return state;
    }
}
