import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';
import ProductRate from 'components/product/rate';

export default (props) => {
    const { product_id } = useParams();
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [y, setY] = useState(0);
    const scroller = useRef();

    const getList = async () => {
        const result = await Services.product.comments({ product_id });
        if (result.status > 0) {
            const newList = list.concat(result.data.comments.rows)
            setList(newList);
            setHasMore(newList < result.data.count);
        }
    }

    useEffect(() => {
        getList();
        if (scroller.current) {
            scroller.current.onScroll = (e) => {
                setY({
                    y: e.target.scrollTop,
                    offset: e.target.offsetHeight + 20 + e.target.scrollTop > e.target.scrollHeight
                });
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);
    useEffect(() => {
        if (y.offset) {
            getList();
        }
    }, [y])
    return <div ref={scroller} className={styles.productRate}>
        {list.map((item, key) => {
            return <ProductRate key={key} {...item} />
        })}
    </div>;
}