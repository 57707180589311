export const user = {
    login: { url: '/wxApi/regAndLogin' },
    center: { method: 'get', url: '/wxApi/user/center' },
    createArticle: { url: '/wxApi/user/createArticle' },
    delArticle: { url: '/wxApi/user/delArticle' },
    favProducts: { url: '/wxApi/user/favProducts' },
    share: { url: '/wxApi/user/share' },
    myCredit: { url: '/wxApi/user/myCredit' },
    creditDetail: { url: '/wxApi/user/creditDetail' },
    lottory: { url: '/wxApi/user/lottory' },
    lottoryInit: { url: '/wxApi/user/lottoryInit', method: 'get' },
    exchangeCoupon: { url: '/wxApi/user/exchangeCoupon', method: 'get' },
    coupons: { url: '/wxApi/user/coupons', method: 'post' },
    likeUserArticle: { url: '/wxApi/user/likeUserArticle' },
    replyUserArticle: { url: '/wxApi/user/replyUserArticle' },
    userProductComments: { url: '/wxApi/user/userProductComments' },
    avatar: { url: '/wxApi/user/updateUserAvatar' },
}