import React, { useEffect, useState, useRef } from 'react';
import Services from 'services';
import { Actions } from 'reduxs';
import styles from './index.module.scss';
import Tab from 'components/tab';
import * as qiniu from 'qiniu-js';
import { v1 } from 'uuid';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'library/util';
import { useSelector } from 'react-redux';

export const Publish = (props) => {
    const { paramStatus } = useParams();
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [isPublish, setIsPublish] = useState(!!paramStatus);

    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    const [articles, setArticles] = useState([]);

    const [user, setUser] = useState(null);
    const [y, setY] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const scroller = useRef();
    const likeArticles = useSelector((state) => {
        const like = state.user.toJS().likeArticles;
        return Array.isArray(like) ? like : [];
    });
    useEffect(() => {
        search(true);
        if (scroller.current) {
            scroller.current.search = search;
            scroller.current.onScroll = (e) => {
                setY({
                    y: e.target.scrollTop,
                    offset: e.target.offsetHeight + 20 + e.target.scrollTop > e.target.scrollHeight
                });
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);

    useEffect(() => {
        if (y.offset) {
            search();
        }
    }, [y])

    const search = async (refresh) => {
        const params = {};
        if (page) {
            params.page = page * 10;
        } else {
            params.page = 0;
        }
        if (!refresh && totalPage < page + 1) {
            return;
        }
        const result = await Services.page.userArticleList(params);
        if (result.status > 0) {
            console.log(result, refresh);
            if (refresh) {
                setArticles(result.data.articles.rows);
                setTotalPage(Math.ceil(result.data.articles.count / 10));
                setPage(1);
                setUser(result.data.user);
            } else {
                setArticles(articles.concat(result.data.articles.rows));
                setPage(page + 1);
            }
        }
    }

    const uploadFile = async (event) => {
        if (images.length <= 9) {
            const file = event.currentTarget.files && event.currentTarget.files[0];
            const res = await Services.page.imageUptoken();
            const token = v1();
            const timestamp = new Date().getTime();
            const saveKey = `user/media/${token}${timestamp}`;
            const observable = qiniu.upload(file, saveKey, res.data);
            const observer = {
                next(res) {
                    // ...
                    console.log(res);
                },
                error(err) {
                    // ...
                    console.warn(err);
                },
                complete(res) {
                    // ...
                    const bannerUrl = `${process.env.REACT_APP_QINIU_CDN}/${res.key}`;
                    setImages(images.concat(bannerUrl));
                },
            };
            observable.subscribe(observer);
        }
    }

    const submit = async () => {
        if (!content) {
            Actions.alert.show('请填写内容');
            return;
        }
        if (!images || images.length <= 0) {
            Actions.alert.show('请上传图片');
            return;
        }
        const result = await Services.user.createArticle({ content, attach: { images } });
        if (result) {
            await Actions.alert.show('发布成功');
            setContent('');
            setImages([]);
            setIsPublish(false);
            scroller.current.search(true);
        }
    }

    if (isPublish) {
        return <div ref={scroller} className={styles.container}>
            <section>
                <textarea maxLength={250} value={content} onChange={(event) => {
                    setContent(event.target.value);
                }} placeholder='分享有趣的经历，帮助更多热爱旅行的人' className='textarea' />
                <div className={styles.media}>
                    {images.map((item, key) => {
                        return <div key={key} className={styles.upload}>
                            <div className={styles.imageWrap}>
                                <img src={item} />

                            </div>
                            <i onClick={() => {
                                setImages(images.filter((k, f) => {
                                    return f !== key;
                                }));
                            }} className={`icon iconfont icon-guanbixiao ${styles.close}`}></i>
                        </div>
                    })}
                    {images.length < 9 ? <label className={styles.upload}>
                        <input type='file' onChange={uploadFile} />
                        <i className='icon iconfont icon-paizhao1'></i>
                        <p>上传图片</p>
                    </label> : false}

                    {/* <div className={styles.upload}>
                    <i className='icon iconfont icon-vrbofangqi1'></i>
                    <p>上传图片</p>
                </div> */}
                </div>
            </section>
            <div className={styles.footer} onClick={submit}>
                发 布
        </div>

            <Tab index={2} />
        </div>
    } else {
        return <div ref={scroller} className={styles.container}>
            {user ? <section className={styles.head}>
                <img className={styles.avatar} src={user.avatar} alt='头图' />
                <label>{JSON.parse(user.name)}</label>
                <div onClick={() => {
                    setIsPublish(true);
                }} className={styles.userWrap}>
                    <i className='icon iconfont icon-paizhao1'></i>
                </div>
            </section> : false}
            {articles.map((item, key) => {
                const isLike = (likeArticles || []).indexOf(item.id) > -1;
                return <section key={key} className={styles.articleWrap}>
                    <div className={styles.avatar}>
                        <img src={item.user.avatar} alt='头像' />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.main}>
                            {user && item.user.id === user.id ? <span onClick={async () => {
                                Actions.modal.show('是否确认删除', '温馨提示', [{
                                    name: '取消',
                                    click: () => {
                                        Actions.modal.hide();
                                    }
                                }, {
                                    name: '确定',
                                    click: async () => {
                                        Actions.modal.hide();
                                        const result = await Services.user.delArticle({ id: item.id });
                                        if (result.status > 0) {
                                            articles.splice(key, 1);
                                            setRefresh(refresh + 1);
                                        }
                                    }
                                }])

                            }} className={styles.del}>删除</span> : false}
                            <h3>{JSON.parse(item.user.name)}</h3>
                            <p>{item.content}</p>
                            <div className={styles.media}>
                                {item.user_article_attaches.length === 1 ? <div className={`${styles.upload} ${styles.large}`}>
                                    <div className={styles.imageWrap} onClick={() => {
                                        window.wx.previewImage({
                                            current: item.user_article_attaches[0].wap_url,
                                            urls: [item.user_article_attaches[0].wap_url]
                                        });
                                    }}>
                                        <img src={item.user_article_attaches[0].wap_url} alt='图片' />
                                    </div>
                                </div> : item.user_article_attaches.map((item2, key2) => {
                                    return <div key={key2} className={styles.upload}
                                        onClick={() => {
                                            window.wx.previewImage({
                                                current: item2.wap_url,
                                                urls: item.user_article_attaches.map(e => e.wap_url)
                                            });
                                        }} >
                                        <div className={styles.imageWrap}>
                                            <img src={item2.wap_url} alt='图片' />
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                        <div className={styles.opertion}>
                            <div className={styles.time}>
                                {format.currentTime(new Date(item.created_at.replace(/-/g, '/')).getTime())}
                            </div>

                            <div className={styles.group} onClick={() => {
                                item.isReply = !!!item.isReply;
                                setRefresh(refresh + 1);
                            }}>
                                <i className='icon iconfont icon-xiaoxi'></i>评论
                            </div>
                            {isLike ? <div className={styles.group} onClick={async () => {
                                await Services.user.likeUserArticle({ user_article_id: item.id, type: 'unlike' });
                                item.like--;
                                Actions.user.unlikeArticle(item.id);
                            }}>
                                <i className='icon iconfont icon-xihuan'></i>喜欢{item.like || ''}
                            </div> : <div className={styles.group} onClick={async () => {
                                await Services.user.likeUserArticle({ user_article_id: item.id });
                                item.like++;
                                Actions.user.likeArticle(item.id);
                            }}>
                                    <i className='icon iconfont icon-xihuan1'></i>喜欢{item.like || ''}
                                </div>}

                        </div>
                        {item.isReply ? <div className={styles.reply}>
                            <input value={item.replayContent} onChange={(event) => {
                                item.replayContent = event.target.value;
                                setRefresh(refresh + 1);
                            }} type='text' placeholder='请输入评论内容' />
                            <button onClick={async () => {
                                const result = await Services.user.replyUserArticle({
                                    user_article_id: item.id,
                                    content: item.replayContent,
                                })
                                if (result.status > 0) {
                                    // result.data.created_at = format.time(new Date(result.data.createdAt), 'yyyy-MM-dd hh:mm:ss');
                                    item.user_article_comments = [result.data].concat(item.user_article_comments || []);
                                    item.isReply = false;
                                    setRefresh(refresh + 1);
                                }
                            }}>回复</button>
                        </div> : false}

                        {item.user_article_comments.map((item2, key2) => {
                            return <div className={styles.comments} key={key2}>
                                {/* <span>{format.currentTime(new Date(item2.created_at.replace(/-/g, '/')).getTime())}</span> */}
                                <label>{JSON.parse(item2.user.name)}</label>
                                <p>{item2.content}</p>

                            </div>
                        })}
                    </div>


                </section>
            })
            }
            <Tab index={2} />
        </div >
    }
}