import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import Services from 'services';

const statusMap = ['全部', '审核', '确认中', '签订合同', '代付款', '完成', '退款中', '关闭', '无效'];
const statusIcon = ['icon-dingdanjihe', 'icon-shikebiao', 'icon-qitadingdan', 'icon-dingdan', 'icon-dingdandaifukuan', 'icon-dingdanyichenggong', 'icon-dingdanchulizhong', 'icon-tuichu', 'icon-xiangqing']
export const Comments = (props) => {
    const { paramStatus } = useParams();
    const [limit] = useState(10);
    const [status, setStatus] = useState(paramStatus || 0);
    let [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const history = useHistory();
    useEffect(() => {
        document.getElementById('root').addEventListener('scroll', onScroll);
        return () => {
            document.getElementById('root').removeEventListener('scroll', onScroll);
        }
    });
    useEffect(() => {
        getOrderList(true);
    }, [status]);

    const onScroll = (event) => {
        if (event.target.scrollTop + window.screen.height - 20 > event.target.offsetHeight) {
            getOrderList();
        }
    }

    const getOrderList = async (refresh) => {
        if (refresh) {
            list = [];
        }
        if (!hasMore && !refresh) {
            return;
        }
        const result = await Services.user.userProductComments({ offset: list.length, limit });
        if (result) {
            const newList = [].concat(list, result.data.rows)
            setList(newList);
            setHasMore(result.data.count > newList.length);
        }
    }

    return <div className={styles.container}>
        <section>
            <div className={styles.title}>
                <h2>我的评论</h2>
            </div>
        </section>
        {list.map((item, key) => {
            return <section onClick={() => {
                history.push('/weixin/order/detail/' + item.order_id);
            }} className={styles.orderList}>
                <div className={`${styles.title} ${styles.orderTitle}`}>
                    <h2>{item.content}</h2>
                </div>
                <div className={styles.images}>
                    {item.product_comment_attaches.map((item, key) => {
                        return <img src={item.wap_url} alt='图片' />
                    })}
                </div>
                <div className={styles.footer}>
                    <span>{item.created_at}</span>
                    <button onClick={(event) => {
                        event.stopPropagation();
                        history.push('/weixin/order/detail/' + item.order_id);
                    }}>详情</button>
                </div>
            </section>
        })}
        <footer>
            {!hasMore && list.length > 0 ? <p>已经到底了</p> : false}
            {!hasMore && list.length <= 0 ? <p>暂无订单</p> : false}
        </footer>

    </div>
}