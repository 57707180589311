import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link } from 'react-router-dom';
import Services from 'services';
import ProductRate from 'components/product/rate';
import ProductItem from 'components/product/item';
import { Actions } from 'reduxs';
import { useSelector } from 'react-redux';

const root = document.getElementById('root');
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export const Product = (props) => {
    const scroller = useRef();
    const [y, setY] = useState(0);
    const id = useParams();
    const [product, setProduct] = useState(null);

    const getProduct = async () => {
        const result = await Services.product.detail(id);
        if (result) {
            setProduct(result.data);
            window.wx.ready(() => {
                let banners = [];
                try {
                    banners = JSON.parse(result.data.product.images || '[]');
                } catch (ex) {
                    banners = [result.data.product.images];
                }
                if (typeof (banners) === 'string') {
                    banners = [banners];
                }
                const bannerUrl = banners[0];
                const params = {
                    title: result.data.product.title, // 分享标题
                    desc: '发现一条优质线路，快来看看吧', // 分享描述
                    link: window.location.origin + '/weixin/product/' + result.data.product.id, // 分享链接
                    imgUrl: bannerUrl.indexOf('http') > -1 ? bannerUrl : `${window.location.protocol}${bannerUrl}`, // 分享图标
                    success: () => {
                    }
                };
                window.wx.updateTimelineShareData(params);
                window.wx.updateAppMessageShareData(params);
            });
        }
    };

    useEffect(() => {
        getProduct();
        if (scroller.current) {
            scroller.current.scrollTo(0, 0);

            scroller.current.onScroll = (e) => {
                setY(e.target.scrollTop);
            }
            scroller.current.addEventListener('scroll', scroller.current.onScroll);
            return () => {
                scroller.current.removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, [id]);

    if (product) {
        return <div ref={scroller} className={styles.product}>
            <HeaderRender />
            <BannerRender product={product.product} />
            <ProductHeadRender product={product.product} typeName={product.typeName} />
            <ProductScheduleRender product={product.product} />
            <ProductRateRender product={product.product} comment={product.comment} />
            <ProductDetailRender root={scroller.current} products={product.products} y={y} product={product.product} article={product.article} />
            <FooterRender product={product.product} />
        </div>;
    } else {
        return <div ref={scroller} className={styles.product}></div>;
    }
}

const HeaderRender = (props) => {
    const history = useHistory();
    return <div className={styles.head} onClick={() => {
        console.log('/', window.location.href, window.entrance);
        if (window.location.href === window.entrance) {
            history.push('/weixin/home');
        } else {
            history.goBack();
        }
    }}>
        <div className={styles.backButton}><i className='icon iconfont icon-fanhuijiantou'></i></div>
    </div>
}

const BannerRender = (props) => {
    const [thisIndex, setThisIndex] = useState(0);
    const { product } = props;
    let banners = [];
    try {
        banners = JSON.parse(product.images || '[]');
    } catch (ex) {
        banners = [product.images];
    }
    if (typeof (banners) === 'string') {
        banners = [banners];
    }
    return <div className={styles.bannerWrap}>
        <AutoPlaySwipeableViews autoplay interval={5000} resistance={true} onChangeIndex={(index) => {
            setThisIndex(index);
        }} className={styles.banner}>
            {banners.map((item, key) => {
                return <div key={key} className={styles.bannerItem}>
                    <img src={item} alt="" />
                </div>
            })}
        </AutoPlaySwipeableViews>
        <div className={styles.pagination}>
            <i className='icon iconfont icon-tupian'></i><span>{thisIndex + 1}/{banners.length}</span>
        </div>
        <div className={styles.footer}>
            <span>{product.departure}出发 · {product.supplier}</span>
            <span>编号 · {product.code}</span>
        </div>

    </div>;
}

const ProductHeadRender = (props) => {
    const { product, typeName } = props;
    let recommend = [];
    try {
        recommend = JSON.parse(product.recommend);
    } catch (ex) {
        recommend = [product.recommend];
    }
    if (typeof recommend === 'string') {
        recommend = [recommend];
    }
    return <div className={styles.productHeader}>
        <div className={styles.bigPrice}>
            <dfn>¥</dfn><em>{product.sale_price}</em>
            <span>/人起</span>
            {product.sale_count ? <span>{product.sale_count || 0} 人出游</span> : false}
        </div>
        <h2><em>{typeName}</em>{product.title}</h2>

        <div className={styles.attributes}>
            <ul>
                <li>天数：<span>{product.days}天{product.nights}晚</span></li>
                <li>已售：<span>{product.sold}</span></li>
                <li>浏览：<span>{product.visit}</span></li>
                <li>优惠信息：<span>积分抵扣</span></li>
                <li>抵扣信息：<span>现金券抵扣</span></li>
            </ul>
        </div>
        <div className={styles.recommend}>
            <p><i className="icon iconfont icon-zan"></i>推荐理由</p>
            {(recommend || []).map((item, key) => {
                return <p key={key}>{item}</p>
            })}
        </div>
        <div className={styles.tags}>
            <ul>
                {product.tag.split('|').map((item, key) => {
                    return <li key={key} className={key === 0 ? styles.active : ''}>{item}</li>;
                })}

            </ul>
        </div>
    </div>
}

const ProductRateRender = (props) => {
    const { product, comment } = props;
    const history = useHistory();
    return <div className={styles.rate}>
        <h2><i className="icon iconfont icon-pinglun"></i> 点评 <span>满意度 {((parseFloat(product.journey_score) + parseFloat(product.food_score) + parseFloat(product.traffic_score)) / 15 * 100).toFixed(2)}%</span></h2>
        <div className={styles.rateDetail}>
            <ul>
                <li><strong>行程安排</strong> <em style={{ width: product.journey_score ? parseFloat(product.journey_score) / 5 * 150 + 'px' : '150px' }} className={styles.progree}></em> <span>{product.journey_score ? product.journey_score : 5}</span></li>
                <li><strong>餐饮住宿</strong> <em style={{ width: product.food_score ? parseFloat(product.food_score) / 5 * 150 + 'px' : '150px' }} className={styles.progree}></em> <span>{product.food_score ? product.food_score : 5}</span></li>
                <li><strong>旅行交通</strong> <em style={{ width: product.traffic_score ? parseFloat(product.traffic_score) / 5 * 150 + 'px' : '150px' }} className={styles.progree}></em> <span>{product.traffic_score ? product.traffic_score : 5}</span></li>
            </ul>
        </div>
        {comment[0] ? <>
            <ProductRate {...comment[0]} />
            <button onClick={() => {
                history.push('/weixin/productRate/' + product.id);
            }} className={styles.more}>查看更多评价</button></> : false}

    </div>
}

const ProductDetailRender = (props) => {
    const { product, y, article, products, root } = props;
    const { ProductJourneys: productJourneys = [] } = product;
    const [index, setIndex] = useState(0);
    const navRef = useRef();
    const detailRef = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const ref6 = useRef();
    const [showFixedNav, setShowFixedNav] = useState(false);
    useEffect(() => {
        setShowFixedNav(detailRef.current && y > detailRef.current.offsetTop);
        if (y >= ref6.current.offsetTop - navRef.current.clientHeight) {
            setIndex(5);
        } else if (ref5.current && y >= ref5.current.offsetTop - navRef.current.clientHeight) {
            setIndex(4);
        } else if (ref4.current && y >= ref4.current.offsetTop - navRef.current.clientHeight) {
            setIndex(3);
        } else if (y >= ref3.current.offsetTop - navRef.current.clientHeight) {
            setIndex(2);
        } else if (y >= ref2.current.offsetTop - navRef.current.clientHeight) {
            setIndex(1);
        } else if (y >= ref1.current.offsetTop - navRef.current.clientHeight) {
            setIndex(0);
        }
    }, [y])

    const navRender = () => {
        return <div className={styles.productNav}>
            <ul>
                <li onClick={() => {
                    root.scrollTo(0, ref1.current.offsetTop - navRef.current.clientHeight);
                }} className={index === 0 ? styles.active : ''}><a>行程安排</a></li>
                <li onClick={() => {
                    root.scrollTo(0, ref2.current.offsetTop - navRef.current.clientHeight);
                }} className={index === 1 ? styles.active : ''}><a>费用说明</a></li>
                <li onClick={() => {
                    root.scrollTo(0, ref3.current.offsetTop - navRef.current.clientHeight);
                }} className={index === 2 ? styles.active : ''}><a>预定须知</a></li>
                <li onClick={() => {
                    root.scrollTo(0, ref4.current.offsetTop - navRef.current.clientHeight);
                }} className={index === 3 ? styles.active : ''}><a>如何预定</a></li>
                {product.visa_info ?
                    <li onClick={() => {
                        root.scrollTo(0, ref5.current.offsetTop - navRef.current.clientHeight);
                    }} className={index === 4 ? styles.active : ''}><a>签证信息</a></li> : false
                }

                <li onClick={() => {
                    root.scrollTo(0, ref6.current.offsetTop - navRef.current.clientHeight);
                }} className={index === 5 ? styles.active : ''}><a>同类推荐</a></li>
            </ul>
        </div>;
    }
    return <div className={styles.productDetail} ref={detailRef}>
        <div ref={navRef}>
            {navRender()}
        </div>
        {showFixedNav ? <div className={styles.navFixed}>
            {navRender()}
        </div> : false}

        <div className={styles.journey} ref={ref1}>
            <h2>行程安排</h2>
            {productJourneys.map((item, key) => {
                let images = [];
                try {
                    if (item.images) {
                        images = JSON.parse(item.images);
                    }
                    if (!Array.isArray(images)) {
                        images = [images];
                    }
                } catch (ex) {
                    images = [item.images];
                }
                return <div key={key} className={styles.productJourney}>
                    <h3><i className="icon iconfont icon-zhoubianyou"></i> 第{item.day}天 {item.title}</h3>
                    <h4><i className="icon iconfont icon-zaocan"></i> 早餐：{item.breakfast} 中餐： {item.lunch} 晚餐：{item.dinner}</h4>
                    <h5><i className="icon iconfont icon-kezhangongyu"></i> 住宿：{item.stay}</h5>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    <div className={styles.journeyImages}>
                        {images.map((item, key) => {
                            return <p key={key}>
                                <i className="icon iconfont icon-tupian"></i>
                                <img src={item.url} />
                            </p>
                        })}

                    </div>
                </div>
            })}
        </div>

        <div className={styles.productContent} ref={ref2}>
            <h2>费用说明</h2>
            {
                product.include ? <>
                    <h3>费用包含：</h3>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: product.include }}></div>
                </> : false
            }

            {
                product.include ? <>
                    <h3>费用不包含：</h3>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: product.exclude }}></div>
                </> : false
            }
        </div>

        <div className={styles.productContent} ref={ref3}>
            <h2>预定须知</h2>
            {
                product.must_known ? <>
                    <h3>预定说明：</h3>
                    <div className={styles.content} dangerouslySetInnerHTML={{ __html: product.must_known }}></div>
                </> : false
            }
        </div >

        <div className={styles.productContent} ref={ref4}>
            <h2>如何预定</h2>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: article.content }}></div>
        </div >
        {product.visa_info ?
            <div className={styles.productContent} ref={ref5}>
                <h2>签证信息</h2>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: product.visa_info }}></div>
            </div > : false
        }
        <div className={styles.productContent} ref={ref6}>
            <h2>同类推荐</h2>
            {products.map((item, key) => {
                return <ProductItem key={key} {...item} />
            })}
        </div >
    </div >
}

const ProductScheduleRender = (props) => {
    const { product } = props;
    const history = useHistory();
    if (product.current_travel_date) {
        return <div className={styles.schedule}>
            <h2><i className="icon iconfont icon-rili"></i> 出发日期</h2>
            <div className={styles.scheduleList}>
                <ul>
                    {product.current_travel_date && product.current_travel_date.split(',').map((item, key) => {
                        return <li onClick={() => {
                            history.push('/weixin/order/confirm', {
                                product: product,
                                travel_at: item
                            })
                        }} key={key} >
                            <h4>{item}</h4>
                        </li>
                    })}
                </ul>
                <button onClick={() => {
                    history.push('/weixin/order/confirm', {
                        product: product,
                    })
                }} >更多班期</button>
            </div>
        </div>
    } else {
        return false;
    }
}

const FooterRender = (props) => {
    const { product } = props;
    const favProducts = useSelector((state) => {
        return state.product.toJS().favProducts || [];
    });

    const isInFav = favProducts.includes(product.id);

    const history = useHistory();

    return <div className={styles.footer}>
        <button className={styles.share} onClick={() => {
            Actions.kf.show();
        }}><i className="icon iconfont icon-kefu"></i>客服</button>
        <button onClick={() => {
            Actions.modal.show('请点击右上角分享按钮，分享给好友或者朋友圈', null, [{
                name: '确定', click: () => {
                    Services.user.share();
                    Actions.modal.hide();
                }
            }])
        }} className={styles.share}><i className="icon iconfont icon-fenxiang"></i>分享</button>
        <button onClick={() => {
            if (isInFav) {
                Actions.product.removeFav(product.id);
            } else {
                Actions.product.addFav(product.id);
            }
        }} className={`${styles.share} ${isInFav ? styles.active : ''}`}><i className="icon iconfont icon-shoucangxiao1"></i>{isInFav ? '取消' : '收藏'}</button>
        <button className={styles.buy} onClick={() => {
            history.push('/weixin/order/confirm', {
                product
            })
        }}>立即预定</button>
    </div>
}

export default Product;