import {
    MODAL_SHOW, MODAL_HIDE,
    TRAVEL_MODAL_SHOW, TRAVEL_MODAL_HIDE,
    KF_MODAL_SHOW, KF_MODAL_HIDE,
} from '../../../constants';
import * as Immutable from 'immutable';

const initialState = Immutable.fromJS({ status: 'HIDE', title: '', content: '', buttons: [] });

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function modal(state = initialState, action) {
    switch (action.type) {
        case MODAL_SHOW:
            action.data.status = 'SHOW';
            return state.merge(action.data);
        case MODAL_HIDE:
            return state.merge({ status: 'HIDE' });
        default:
            return state;
    }
}
export function travelModal(state = initialState, action) {
    switch (action.type) {
        case TRAVEL_MODAL_SHOW:
            action.data.status = 'SHOW';
            return state.merge(action.data);
        case TRAVEL_MODAL_HIDE:
            return state.merge({ status: 'HIDE' });
        default:
            return state;
    }
}
export function kfModal(state = initialState, action) {
    switch (action.type) {
        case KF_MODAL_SHOW:
            return state.merge({ status: 'SHOW' });
        case KF_MODAL_HIDE:
            return state.merge({ status: 'HIDE' });
        default:
            return state;
    }
}