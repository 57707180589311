import Services from 'services';
const sign = '37ef9b97d17307cf20469dba1cb0e06363437e9dc76a81c6298aedf363502e6a97c72d5d3887240c35131bf5a3dbd89bdb097b9d';

export default async (product_id) => {
    const orderResult = await Services.product.getOrder({ product_id });
    let [c1, c2, c3, c4, c5] = ['', '', '', '', ''];
    let uid;
    if (orderResult) {
        uid = orderResult.data.user.id;
        const { order } = orderResult.data;
        if (order) {
            [c1, c2, c3, c4, c5] = [
                order.order_no,
                order.sale_price,
                ['无效', '待支付', '支付成功'][order.status],
                order.Product.title,
                order.ProductSku.title
            ]
        }
    }
    window.location.href = `https://yzf.qq.com/xv/web/static/chat/index.html?sign=${sign}&uid=${uid}&c1=${encodeURIComponent(c1)}&c2=${encodeURIComponent(c2)}&c3=${encodeURIComponent(c3)}&c4=${encodeURIComponent(c4)}&c5=${encodeURIComponent(c5)}`
}