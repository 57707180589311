import React, { useMemo, } from 'react';
import { Main, } from './layouts';
import HRComponents from 'components';
import { Route } from 'react-router-dom';
import Home from './home';
import ProductRate from './product/rate';
import Special from './special';
import SpecialSubmit from './special/submit';
import Target from './target';
import Search from './search';
import Product from './product';
import * as Login from './login';
import * as Order from './order';
import * as User from './user';
import * as Article from './article';
export const Routes = [{
    path: '/',
    component: Main,
    routes: [
        {
            path: '/home',
            component: Home,
        },
        {
            path: '/target/:targetId',
            component: Target,
        },
        {
            path: '/search/:keyword?',
            component: Search,
        },
        {
            path: '/:parent?/:param?/product/:id',
            component: Product,
        },
        {
            path: '/productRate/:product_id',
            component: ProductRate
        },
        {
            path: '/login/home',
            component: Login.Home,
        },
        {
            path: '/login/mobile',
            component: Login.Mobile,
        },
        {
            path: '/order/confirm',
            component: Order.Confirm,
        },
        {
            path: '/order/info',
            component: Order.Info,
        },
        {
            path: '/order/detail/:id',
            component: Order.Detail,
        },
        {
            path: '/user/center',
            component: User.Center,
        },
        {
            path: '/user/publish/:paramStatus?',
            component: User.Publish,
        },
        {
            path: '/user/orders/:paramStatus?',
            component: User.Orders,
        },
        {
            path: '/user/credit',
            component: User.Credit,
        },
        {
            path: '/user/lottory',
            component: User.LotteryPage,
        },
        {
            path: '/user/coupon',
            component: User.Coupon,
        },
        {
            path: '/user/lottoryRule',
            component: User.LottoryRule,
        },
        {
            path: '/user/task',
            component: User.Task,
        },
        {
            path: '/user/favProducts',
            component: User.FavProducts,
        },
        {
            path: '/user/userProductComment',
            component: User.Comments,
        },
        {
            path: '/special',
            component: Special,
        },
        {
            path: '/specialSubmit',
            component: SpecialSubmit,
        },
        {
            path: '/article/list',
            component: Article.List,
        },
        {
            path: '/article/detail/:id',
            component: Article.Detail,
        }
    ]
}];

const highOrderedRoute = (route) => {
    if (route.routes) {
        route.routes = route.routes.map((_route, i) => {
            return highOrderedRoute(_route);
        })
        return route;
    } else {
        let thisComponent = route.component;
        if (route.title) {
            thisComponent = HRComponents.HighOrderChangeTitle(thisComponent);
        }
        thisComponent = HRComponents.Share(thisComponent);
        route.component = thisComponent;
        return route;
    }
}

const RouteWithSubRoutes = (route) => {

    const renderChildren = useMemo(() => {
        return (<Route path={'/weixin' + route.path} exact={route.exact || false} render={(props) => {
            if (route.routes) {
                return (<route.component {...props}>
                    {
                        route.routes.map((_route, i) => {
                            return (<RouteWithSubRoutes key={i} {..._route} />);
                        })
                    }
                </route.component>);
            } else {
                return (<route.component {...props} title={route.title || '绿地G优尊享会'} share={route.share} />);
            }
        }} />);
    }, [route]);

    return renderChildren;

};

export const RouteRender = () => {
    return <>
        {Routes.map((route, i) => {
            const thisRoute = highOrderedRoute(route);
            return (
                <RouteWithSubRoutes key={i} {...thisRoute} />
            );
        })}

    </>
}
