import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import { Actions } from 'reduxs';

export const KF = (props) => {
    const modal = useSelector((state) => {
        return state.kfModal.toJS();
    });
    if (modal.status === 'SHOW') {
        return <div className={styles.modal} >
            <div className={styles.modalBack} onClick={() => {
                Actions.kf.hide();
            }}></div>
            <div className={styles.modalTitle}>
                <div className={styles.left}></div>
                <div className={styles.middle}>联系客服</div>
                <div className={styles.right} onClick={() => {
                    Actions.kf.hide();
                }}>
                    <i className='iconfont icon-shibai2'></i>
                </div>
            </div>
            <div className={styles.modalContent}>
                <h3>*本产品由金中旅直营，并提供咨询/预约/售后服务</h3>
                <div className={styles.modalContentTitle} onClick={() => {
                    window.location.href = 'https://p.qiao.baidu.com/cps/chat?siteId=5783045&userId=5313259&siteToken=91467ec3858321e022a8faf622b6862b';
                }}>
                    <div className={styles.left}>
                        <h2>在线客服 <em>推荐</em></h2>
                        <p>温馨提示：为了节约您的时间，建议您优先选择在线客服</p>
                    </div>
                    <div className={styles.right}>
                        <i className={'icon iconfont icon-xiaoxi ' + styles.icon}></i>
                    </div>
                </div>
                <h3></h3>
                <div className={styles.modalContentTitle} onClick={() => {
                    window.location.href = 'tel:18149775519';
                }}>
                    <div className={styles.left}>
                        <h2>电话客服</h2>
                        <p><b>境内拨打</b></p>
                        <p>18149775519</p>
                        <p>服务时间（北京时间）：周一至周日00:00至23:59</p>
                        <p>电话接通后，请告知客服产品编号</p>
                    </div>
                    <div className={styles.right}>
                        <i className={'icon iconfont icon-dianhua ' + styles.icon}></i>
                    </div>
                </div>
            </div>
        </div>
    } else {
        return false;
    }
}