import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Tab from 'components/tab';
import Services from 'services';
import * as qiniu from 'qiniu-js';
import { v1 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const statusMap = ['全部', '审核', '确认中', '签订合同', '代付款', '完成', '退款中', '关闭', '无效'];
const statusIcon = ['icon-dingdanjihe', 'icon-shikebiao', 'icon-qitadingdan', 'icon-dingdan', 'icon-dingdandaifukuan', 'icon-dingdanyichenggong', 'icon-dingdanchulizhong', 'icon-tuichu', 'icon-xiangqing']
const levelMap = ['普通会员', '黄金会员', '铂金会员', '钻石会员'];
export const Center = (props) => {
    const [user, setUser] = useState();
    const history = useHistory();
    const [creditInfo, setCreditInfo] = useState(null);
    const [levelMask, setLevelMask] = useState(false);
    const favProducts = useSelector((state) => {
        return state.product.toJS().favProducts || [];
    });

    const uploadFile = async (event) => {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        const res = await Services.page.imageUptoken();
        const token = v1();
        const timestamp = new Date().getTime();
        const saveKey = `user/media/${token}${timestamp}`;
        const observable = qiniu.upload(file, saveKey, res.data);
        const observer = {
            next(res) {
                // ...
                console.log(res);
            },
            error(err) {
                // ...
                console.warn(err);
            },
            complete: async (res) => {
                // ...
                const bannerUrl = `${process.env.REACT_APP_QINIU_CDN}/${res.key}?imageView2/2/w/180`;
                const result = await Services.user.avatar({ avatar: bannerUrl });
                setUser(result.data);
            },
        };
        observable.subscribe(observer);
    }

    useEffect(() => {
        getUserInfo();
    }, []);


    const getUserInfo = async () => {
        const result = await Services.user.center();
        if (result) {
            setUser(result.data);
            setCreditInfo(result.data.creditDetail);
        }
    }

    if (user) {
        return <div className={styles.container} onClick={() => {
            setLevelMask(false);
        }}>
            <div className={styles.head}>
                <label className={styles.avatar}>
                    <input type='file' onChange={uploadFile} />
                    <img src={user.avatar} alt='icon' />
                </label>
                <div className={styles.right}>
                    <h2>{JSON.parse(user.name)}</h2>
                    <p><i className={`icon iconfont icon-jingxuan ${[styles.gray, styles.yellow, styles.blue, styles.white][user.level || 0]}`}></i>
                        {levelMap[user.level || 0]}

                        <i onClick={(event) => {
                            event.stopPropagation();
                            setLevelMask(true);
                        }} className='icon iconfont icon-wenhaoxiao'></i>
                        {levelMask ? <div className={styles.levelMask}>
                            <table border={1} cellPadding={1} cellSpacing={1}>
                                <tr>
                                    <td>获取条件</td>
                                    <td>会员等级</td>
                                </tr>
                                <tr>
                                    <td>注册为会员</td>
                                    <td>普通会员</td>
                                </tr>
                                <tr>
                                    <td>获取积分大于等于2000<br />小于30000</td>
                                    <td>黄金会员</td>
                                </tr>
                                <tr>
                                    <td>获取积分大于等于30000<br />小于50000</td>
                                    <td>铂金会员</td>
                                </tr>
                                <tr>
                                    <td>获取积分大于等于50000</td>
                                    <td>钻石会员</td>
                                </tr>
                            </table>
                        </div> : false}

                    </p>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.top}>
                    <Link className={styles.item} to='/weixin/user/credit'>
                        <h2>我的积分</h2>
                        <p>{creditInfo && creditInfo.current || 0}<small>分</small></p>
                    </Link>
                    <Link className={styles.item} to='/weixin/user/coupon'>
                        <h2>优惠券</h2>
                        <p>可用{user.coupons}张</p>
                    </Link>

                    <Link className={styles.item} to='/weixin/user/favProducts'>
                        <h2>我的收藏</h2>
                        <p>{favProducts.length}条线路</p>
                    </Link>
                </div>
                <section>
                    <div onClick={() => {
                        history.push('/weixin/user/orders');
                    }} className={styles.title} >
                        <h2>我的订单</h2>
                        <p>全部订单&nbsp;</p>
                    </div>
                    <div className={styles.order}>
                        {statusMap.map((item, key) => {
                            if (key > 0) {
                                return <div onClick={() => {
                                    history.push('/weixin/user/orders/' + key);
                                }} key={key} className={`${styles.orderWrap}`}>
                                    <div className={styles.orderItem}>
                                        <i className={'icon iconfont ' + statusIcon[key]}></i>
                                        <p>{item}</p>
                                    </div>
                                </div>;
                            } else {
                                return false;
                            }
                        })}
                    </div>
                </section>

                <div className={styles.list}>
                    <Link to='/weixin/user/task' className={styles.item}>
                        <div className={styles.left}>
                            <i className='icon iconfont icon-xiangqing'></i>
                            <p>赚取积分</p>
                        </div>
                        <i className='icon iconfont icon-jinrujiantou'></i>
                    </Link>
                    <Link to='/weixin/user/publish' className={styles.item}>
                        <div className={styles.left}>
                            <i className='icon iconfont icon-yijianfankui'></i>
                            <p>我的游记</p>
                        </div>
                        <i className='icon iconfont icon-jinrujiantou'></i>
                    </Link>
                    <Link to='/weixin/article/list' className={styles.item}>
                        <div className={styles.left}>
                            <i className='icon iconfont icon-dianziqianmingx'></i>
                            <p>关于我们</p>
                        </div>
                        <i className='icon iconfont icon-jinrujiantou'></i>
                    </Link>
                </div>
            </div>
            <Tab index={4} />
        </div>;
    } else {
        return false;
    }
}