import React, { useState, useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styles from './index.module.scss';
import Services from 'services';
import weixinIcon from 'images/icon/weixin.png';

export const Home = (props) => {
    return <div className={styles.login}>
        <h1>Hi~<br />欢迎进入金中旅</h1>
        <button className={styles.weixin}>
            <img src={weixinIcon} /> 微信授权登录
        </button>
        <button className={styles.normal}>
            手机号注册/登录
        </button>
    </div>
}