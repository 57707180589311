import { ADD_FAV, REMOVE_FAV } from '../../constants';
import * as Immutable from 'immutable';
const params = {
    favProducts: []
};
const initialState = Immutable.fromJS(params);

/**
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function product(state = initialState, action) {
    let favProducts;
    switch (action.type) {
        case ADD_FAV:
            favProducts = state.get('favProducts') || [];
            favProducts = favProducts.unshift(action.data);
            return state.set('favProducts', favProducts);
        case REMOVE_FAV:
            favProducts = state.get('favProducts') || [];
            favProducts = favProducts.filter(e => e !== action.data);
            favProducts[action.data.key] = action.data.traveller;
            return state.set('favProducts', favProducts);
        default:
            return state;
    }
}
