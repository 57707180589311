export const weixin = {
    jsSdkSignature: {
        method: 'get',
        url: '/wxApi/jsSdkSignature'
    },
    currentUser: {
        method: 'get',
        url: '/wxApi/currentUser'
    },
    sms: {
        url: '/wxApi/sms'
    }
}