import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Services from 'services';
import ProductItem from 'components/product/item';
import Tab from 'components/tab';
import { format, validate } from 'library/util';
import { Actions } from 'reduxs';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default () => {
    const [pageInfo, setPageInfo] = useState();
    const scroller = useRef();
    const [type, setType] = useState(0);
    const [mobile, setMobile] = useState('');
    const [target, setTarget] = useState('');
    const [departure, setDeparture] = useState('上海');
    const history = useHistory();

    useEffect(() => {
        getHomePage();

    }, []);

    const getHomePage = async () => {
        const result = await Services.page.special();
        if (result) {
            setPageInfo(result.data);
            window.wx.ready(() => {
                let banners = result.data && result.data.banner;
                const params = {
                    title: '上海金中旅官方网站.旅游.团建.定制', // 分享标题
                    desc: '金中旅游.出行省心.玩得尽兴.安全保障', // 分享描述
                    link: window.location.href, // 分享链接
                    imgUrl: banners.length > 0 ? window.location.protocol + banners[0].value : false, // 分享图标
                    success: () => {
                    }
                };
                window.wx.updateTimelineShareData(params);
                window.wx.updateAppMessageShareData(params);
            });
        }
    }
    const submit = () => {
        if (!validate.mobile(mobile)) {
            Actions.alert.show('请填写正确手机号');
            return;
        }
        if (!target) {
            Actions.alert.show('请填写目的地');
            return;
        }
        history.push('/weixin/specialSubmit', {
            target,
            departure,
            mobile
        });

    };

    return <div ref={scroller} className={styles.product}>
        {pageInfo ? <>
            <BannerRender banners={pageInfo && pageInfo.banner} type={type} setType={setType} />
            <div className={styles.beginWrap}>
                <div className={styles.begin}>
                    <label>{departure}</label>
                    <i>→</i>
                    <input onChange={(event) => {
                        setTarget(event.target.value);
                    }} type='text' placeholder='目的地' value={target} />
                </div>
                <div className={styles.mobile}>
                    <label>+86</label>
                    <input value={mobile} onChange={(event) => {
                        setMobile(event.target.value);
                    }} type='tel' placeholder='请填写手机号，便于联系您' />
                </div>
                <button onClick={() => {
                    submit();
                }}>马上为我定制</button>
            </div>

            <div className={styles.descWrap}>
                <div className={styles.title}>
                    金中旅定制·出行省心·玩的尽兴
            </div>
                <AutoPlaySwipeableViews
                    className={styles.slideContainer}
                    containerStyle={{ height: 30 }}
                    interval={3000}
                    axis='y'
                    disabled
                    autoplay
                >
                    <div class={styles.subTitle}>想玩些有特色的，但是没有渠道了解？</div>
                    <div class={styles.subTitle}>懒得做攻略？不会做攻略？没空做攻略？</div>
                    <div class={styles.subTitle}>不想跟团游，想要自由、轻松的行程？</div>
                    <div class={styles.subTitle}>带小孩、老人不知道如何规划行程？</div>
                    <div class={styles.subTitle}>蜜月旅行想要完美舒适的服务体验？</div>
                    <div class={styles.subTitle}>异国他乡想要有保障的服务？</div>
                    <div class={styles.subTitle}>想玩些有特色的，但是没有渠道了解？</div>
                    <div class={styles.subTitle}>懒得做攻略？不会做攻略？没空做攻略？</div>
                </AutoPlaySwipeableViews>
                <div className={styles.content}>
                    <ul>
                        <li>
                            <i className='icon iconfont icon-youji'></i>
                            <p>1V1定制</p>
                        </li>
                        <li>
                            <i className='icon iconfont icon-dangdiwanle'></i>
                            <p>设计路线</p>
                        </li>
                        <li>
                            <i className='icon iconfont icon-libao'></i>
                            <p>代订资源</p>
                        </li>
                        <li>
                            <i className='icon iconfont icon-tejiajipiao'></i>
                            <p>出行保障</p>
                        </li>
                    </ul>
                </div>
            </div>
            <ProductListRender targets={(pageInfo && pageInfo.thisTragets) || []} products={(pageInfo && pageInfo.serializeProducts) || []} />
        </> : false}
        <Tab index={1} />
    </div>
}

const BannerRender = (props) => {
    const { banners = [], type, setType } = props;
    return <div className={styles.bannerWrap}>
        <AutoPlaySwipeableViews interval={5000} autoplay resistance={true} className={styles.banner}>
            {banners.map((item, key) => {
                return <div className={styles.swiper} key={key}>
                    <img src={item.value} width="100%" alt="" />
                </div>
            })}
        </AutoPlaySwipeableViews>
        <div className={styles.navBar}>
            <ul>
                <li onClick={() => { setType(0) }} className={type === 0 ? styles.active : ''}>个人定制</li>
                <li onClick={() => { setType(1) }} className={type === 1 ? styles.active : ''}>公司定制</li>
                <li onClick={() => { setType(2) }} className={type === 2 ? styles.active : ''}>会务年会</li>
                <li onClick={() => { setType(3) }} className={type === 3 ? styles.active : ''}>租车</li>
            </ul>
        </div>
    </div>;
}


const ProductListRender = (props) => {
    const { products } = props;
    const [parentIndex, setParentIndex] = useState(0);
    const [parents, setParents] = useState([]);
    const [children, setChilren] = useState([]);
    const [childrenIndex, setChildrenIndex] = useState(0);
    const [selectChild, setSelectChild] = useState(null);
    const history = useHistory();
    useEffect(() => {
        setParents(props.targets.filter(e => e.status > 0 && e.parent_id == 210));
    }, [props.targets]);
    const selectedParent = parents[parentIndex];

    useEffect(() => {
        if (selectedParent) {
            setChilren(props.targets.filter(e => e.status > 0 && e.parent_id === selectedParent.id));
        }
    }, [selectedParent]);

    useEffect(() => {

    }, [selectChild]);
    const parentTargetId = children[childrenIndex] && children[childrenIndex].id;
    return <section className={styles.section}>
        <div className={styles.sectionHeader}><strong>推荐线路</strong></div>
        <div className={styles.target}>
            <ul className={styles.parent}>
                {parents && parents.map((item, key) => {
                    return <li className={key === parentIndex ? styles.active : ''} onClick={() => {
                        setParentIndex(key);
                        setChildrenIndex(0);
                    }} key={key}><p>{item.name}</p>
                        <p className={key === parentIndex ? styles.activeBottom : styles.bottom}></p></li>;
                })}

            </ul>
            <ul className={styles.children}>
                {children && children.map((item, key) => {
                    return <li onClick={() => {
                        setChildrenIndex(key);
                    }} key={key} className={childrenIndex === key ? styles.active : ''}>
                        {item.name}
                    </li>;
                })}
            </ul>
        </div>
        {(products[parentTargetId] || []).map((item, key) => {
            if (key <= 10) {
                return <ProductItem key={key} {...item} />;
            } else {
                return false;
            }
        })}
        {selectedParent ? <div className={styles.toTarget} onClick={() => {
            history.push('/weixin/target/' + selectedParent.id);
        }}>
            获取更多{selectedParent.name}产品信息
        </div> : false}

    </section>
}
