import {
    USER_INVITER_ID,
    USER_OPEN_ID,
    USER_BASE_INFO,
    USER_UNION_ID,
    IS_STAFF,
    STAFF_MENU,
    USER_ARTICLE_LIKE,
    USER_ARTICLE_DISLIKE
} from '../../constants';

export const user = {
    inviter: (id) => {
        return { type: USER_INVITER_ID, data: id };
    },
    openId: (openId) => {
        return { type: USER_OPEN_ID, data: openId };
    },
    baseInfo: (info) => {
        return { type: USER_BASE_INFO, data: info };
    },
    unionId: (unionId) => {
        return { type: USER_UNION_ID, data: unionId };
    },
    isStaff: (isStaff) => {
        return { type: IS_STAFF, data: isStaff };
    },
    staffMenu: (data) => {
        return { type: STAFF_MENU, data: data };
    },
    likeArticle: (data) => {
        return { type: USER_ARTICLE_LIKE, data: data };
    },
    unlikeArticle: (data) => {
        return { type: USER_ARTICLE_DISLIKE, data: data };
    }
};
