import React, { useEffect, useState } from 'react';
import Services from 'services';
import { Link, useParams } from 'react-router-dom';
import styles from './index.module.scss';
export const Detail = (props) => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);

    const getArticle = async () => {
        const result = await Services.article.detail({ id });
        if (result.status > 0) {
            setArticle(result.data);
        }
    }

    useEffect(() => {
        getArticle();
    }, []);
    if (article) {
        return <div className={styles.detail}>
            <h1>{article.title}</h1>
            <pre dangerouslySetInnerHTML={{ __html: article.content }}>
            </pre>
        </div>
    } else {
        return false;
    }
}