/**
 *
 * @author by z-benjamin on 2017/5/25.
 * @name index
 *
 */
export const format = {
    bankCard: (card) => {
        return card.replace(/\s/g, '').replace(/(\d{4})/g, '$1' + ' ').trim();
    },
    pascal: (name) => {
        return name.replace(/^(\w)(.*?)$/g, function($0, $1, $2) {
            return $1.toUpperCase() + $2.toLowerCase();
        });
    },
    idCard: (idCard, replace) => {
        if (idCard) {
            var _replace = replace || '*';
            idCard = idCard.replace(/\s/g, '');
            return idCard.replace(/^(\d{3})\d+?(\d{3})$/g, '$1' + _replace.repeat(idCard.length - 6) + '$2').trim();
        }
    },
    mobile: (mobile, replace) => {
        var _replace = replace || '-';
        mobile = mobile.replace(/\s/g, '');
        return mobile.replace(/(\d{3})(\d{4})/g, '$1' + _replace + '$2' + _replace).trim();
    },
    currentTime: (timeStamps) => {
        //秒差
        const diff = (new Date().getTime() - timeStamps) / 1000;
        const date = new Date(timeStamps);
        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        let year = month * 12;
        if (diff < 10 * minute) {
            return '刚刚';
        } else if (diff < hour) {
            return parseInt(diff / minute, 0) + '分钟前';
        } else if (diff < 10 * hour) {
            return parseInt(diff / hour, 0) + '小时前';
        } else if (timeStamps > new Date(format.time(new Date(), 'yyyy/MM/dd 00:00:00'))) {
            return '今天';
        } else if (timeStamps > new Date(format.time(new Date(new Date().getTime() - 3600 * 24 * 1000), 'yyyy/MM/dd 00:00:00'))) {
            return '昨天';
        } else {
            return format.time(new Date(timeStamps), 'MM-dd');
        }
    },
    time: (time, format) => {
        var map = {
            'M': time.getMonth() + 1, //月份
            'd': time.getDate(), //日
            'h': time.getHours(), //小时
            'm': time.getMinutes(), //分
            's': time.getSeconds(), //秒
            'q': Math.floor((time.getMonth() + 3) / 3), //季度
            'S': time.getMilliseconds() //毫秒
        };
        format = format.replace(/([yMdhmsqS])+/g, function(all, t) {
            var v = map[t];
            if (v !== undefined) {
                if (all.length > 1) {
                    v = '0' + v;
                    v = v.substr(v.length - 2);
                }
                return v;
            } else if (t === 'y') {
                return (time.getFullYear() + '').substr(4 - all.length);
            }
            return all;
        });
        return format;
    },
    nextTime: (timeStamps) => {
        //秒差
        const diff = (timeStamps - new Date().getTime()) / 1000;
        let minute = 60;
        let hour = minute * 60;
        let day = hour * 24;
        let month = day * 30;
        let year = month * 12;
        if (diff < hour) {
            return '1小时内';
        } else if (diff < day) {
            return parseInt(diff / hour, 10) + '小时内';
        } else {
            return parseInt(diff / day, 10) + '天内';
        }
    }
};
