import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import ProductItem from 'components/product/item';
import Services from 'services';

const filterCities = ['不限', '上海', '杭州', '南京', '大连', '北京'];
const filterStartCities = ['不限', '上海', '北京', '天津'];
const filterDays = ['不限', '1日', '2日', '3日', '4日', '5日', '6日', '更多'];
const filterPrices = ['不限', '2千内', '2001-5000', '5千-1万', '1万-2万', '2万以上'];
const filterAttibute = ['不限', '跟团游', '定制游', '亲子游', '当地游', '自由行', '自驾车', '户外游', '单项目'];

const orders = ['综合评分由低到高', '关注度由高到低', '关注度由低到高', '价格由低到高', '价格由高到低', '销量由高到低', '销量由低到高']
export default () => {
    const { keyword: words } = useParams();
    const [_keyword, _targetType, _departure = 0, _travelRange, _days = 0, _priceRange = 0, _target, _attribute = 0, _orderBy = 0, _page, _limit] = (words || '').split('-');
    const history = useHistory();
    const [keyword, setKeyword] = useState(_keyword);
    const [navIndex, setNavIndex] = useState(null);
    const [searchResult, setSearchResult] = useState(null);

    const [targets, setTargets] = useState(null);
    const [firstTarget, setFirstTarget] = useState(false);
    const [secondTarget, setSecondTarget] = useState(false);
    const [threeTarget, setThreeTarget] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFixedNav, setShowFixedNav] = useState(false);

    const [products, setProducts] = useState([]);
    const [y, setY] = useState(0);
    const scroller = useRef();
    const searchBox = useRef();

    const [city, setCity] = useState(parseInt(_departure));
    const [days, setDays] = useState(parseInt(_days));
    const [price, setPrice] = useState(parseInt(_priceRange));
    const [attribute, setAttribute] = useState(parseInt(_attribute));
    const [target, setTarget] = useState(null);

    const [sort, setSort] = useState(parseInt(_orderBy));
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    const search = async (refresh) => {
        const params = {};
        if (keyword) {
            params.keywords = keyword;
        }
        if (target) {
            params.targetType = target.id;
        }
        if (price) {
            params.priceRange = price;
        }
        if (attribute) {
            params.attribute = attribute;
        }
        if (city) {
            params.departure = city;
        }
        if (sort) {
            params.orderBy = sort;
        }
        if (page) {
            params.page = refresh ? 0 : page * 10;
        }
        if (!refresh && totalPage < page + 1) {
            return;
        }
        const result = await Services.page.search(params);
        if (result.status > 0) {
            if (refresh) {
                setProducts(result.data.productList);
                setTotalPage(Math.ceil(result.data.count / 10));
                setPage(1);
            } else {
                setProducts(products.concat(result.data.productList));
                setPage(page + 1);
            }
        }
        setNavIndex(null);
    }

    const getTargets = async () => {
        const result = await Services.page.targets();
        if (result.status > 0) {
            const targetResult = result.data.targets.filter(e => e.status > 0).sort((a, b) => b - a);
            setTargets(targetResult);
        }
    }

    useEffect(() => {
        getTargets();
        if (scroller.current) {
            scroller.current.search = search;
            scroller.current.onScroll = (e) => {
                setY({
                    y: e.target.scrollTop,
                    offset: e.target.offsetHeight + 20 + e.target.scrollTop > e.target.scrollHeight
                });
            }
            document.getElementById('root').addEventListener('scroll', scroller.current.onScroll);
            return () => {
                document.getElementById('root').removeEventListener('scroll', scroller.current.onScroll);
            }
        }
    }, []);

    useEffect(() => {
        if (navIndex === 0 || navIndex) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [navIndex]);

    useEffect(() => {
        setShowFixedNav(searchBox.current && y && y.y > searchBox.current.clientHeight);
        if (y.offset) {
            search();
        }
    }, [y])


    useEffect(() => {
        if (secondTarget && !secondTarget.id) {
            setTarget(firstTarget);
        }
    }, [secondTarget]);

    useEffect(() => {
        setTarget(threeTarget && threeTarget.id ? threeTarget : secondTarget);
    }, [threeTarget]);

    useEffect(() => {
        if (target) {
            search(true);
        }
    }, [target]);

    useEffect(() => {
        search(true);
    }, [price]);

    useEffect(() => {
        search(true);
    }, [sort]);


    if (targets) {
        const navRender = () => {
            return <ul>
                <li onClick={() => {
                    setNavIndex(navIndex === 0 ? null : 0);
                }} className={navIndex === 0 ? styles.active : ''}>
                    <span>{!showModal ? (target && target.name || '类型') : '类型'}</span>
                </li>
                <li onClick={() => {
                    setNavIndex(navIndex === 1 ? null : 1);
                }} className={navIndex === 1 ? styles.active : ''}>
                    <span>{!showModal ? (price > 0 ? filterPrices[price] : '价格') : '价格'}</span>
                </li>
                <li onClick={() => {
                    setNavIndex(navIndex === 2 ? null : 2);
                }} className={navIndex === 2 ? styles.active : ''}>
                    <span>班期</span>
                </li>
                <li onClick={() => {
                    setNavIndex(navIndex === 3 ? null : 3);
                }} className={navIndex === 3 ? styles.active : ''}>
                    <span>更多</span>
                </li>
                <li onClick={() => {
                    setNavIndex(navIndex === 4 ? null : 4);
                }} className={navIndex === 4 ? styles.active : ''}>
                    <span>排序</span>
                </li>
            </ul>;
        }
        return <div ref={scroller} className={`${styles.search} ${(showModal ? '' : '')}`}>
            <div className={styles.searchBox} ref={searchBox}>
                <div className={styles.back} onClick={() => {
                    if (window.location.href === window.entrance) {
                        history.push('/weixin/home');
                    } else {
                        history.goBack();
                    }
                }}>
                    <i className='icon iconfont icon-fanhuijiantou'></i>
                </div>
                <div className={styles.input}>
                    <i className="icon iconfont icon-sousuo" ></i>
                    <input type="text" placeholder="搜索目的地/关键词"
                        value={keyword}
                        onChange={(event) => {
                            setKeyword(event.target.value);
                        }}
                    />
                    {keyword ? <i className="icon iconfont icon-guanbishixin close" onClick={() => {
                        setKeyword('');
                    }} ></i> : false}
                </div>
                <div className={styles.searchButton} onClick={() => { search(true); }}>
                    搜索
                </div>
            </div>
            <div className={styles.nav}>
                {navRender()}
            </div>


            <div className={styles.productList}>
                {products.map((item, key) => {
                    return <ProductItem to={`${window.location.pathname}/product/${item.id}`} className={styles.product} key={key} {...item} />;
                })}
            </div>


            {showModal || showFixedNav ? <div className={showFixedNav && !showModal ? styles.fixedBox : styles.filterBox}>
                <div className={styles.nav}>
                    {navRender()}
                </div>
                {showModal ? <>
                    <div className={styles.filterContent}>
                        {navIndex === 0 ? <div className={styles.targetFilter}>
                            <div className={styles.targetOne}>
                                {targets.filter(e => e.parent_id === 0).map((item, key) => {
                                    return <div onClick={() => {
                                        setFirstTarget(item);
                                        setSecondTarget(null);
                                        setThreeTarget(null);
                                    }} className={`${styles.targetItem} ${firstTarget.id === item.id ? styles.activeItem : ''}`} key={key}>
                                        {item.name}
                                    </div>;
                                })}
                            </div>
                            <div className={styles.targetTwo}>
                                {firstTarget && firstTarget.id && ([{ name: '不限', id: null }].concat(targets.filter(e => firstTarget.id === e.parent_id)).map((item, key) => {
                                    return <div onClick={() => {
                                        setSecondTarget(item);
                                        setThreeTarget(null);

                                    }} className={`${styles.targetItem} ${secondTarget && secondTarget.id === item.id ? styles.activeItem : ''}`} key={key}>
                                        {item.name}
                                    </div>;
                                }))}
                            </div>
                            <div className={styles.targetThree}>
                                {secondTarget && secondTarget.id && ([{ name: '不限' }].concat(targets.filter(e => secondTarget.id === e.parent_id)).map((item, key) => {
                                    return <div onClick={() => {
                                        setThreeTarget(item);
                                        setShowModal();
                                    }} className={`${styles.targetItem} ${threeTarget && threeTarget.id === item.id ? styles.activeItem : ''}`} key={key}>
                                        {item.name}
                                    </div>;
                                }))}
                            </div>
                        </div> : false}
                        {navIndex === 1 ? <div className={styles.choiceFilter}>
                            <h2>价格区间（元）</h2>
                            <ul>
                                {filterPrices.map((item, key) => {
                                    if (key > 0) {
                                        return <li onClick={() => {
                                            setPrice(key === price ? 0 : key)
                                        }} key={key} className={key === price ? styles.active : ''}>
                                            {item}
                                        </li>;
                                    } else {
                                        return false;
                                    }
                                })}
                            </ul>
                        </div> : false}
                        {navIndex === 2 ? <>
                            <div className={styles.choiceFilter}>
                                <h2>出发日期</h2>
                                <div className={styles.filterInput}>
                                    <input type='text' placeholder='起始日期' />
                                    <span>至</span>
                                    <input type='text' placeholder='结束日期' />
                                </div>
                            </div>
                            <div className={styles.filterFooter}>
                                <button className={styles.filterButton}>查看线路</button>
                            </div></> : false}
                        {navIndex === 3 ? <>
                            <div className={styles.choiceFilter}>
                                <h2>出发城市</h2>
                                <ul>
                                    {filterCities.map((item, key) => {
                                        console.log(city);
                                        if (key > 0) {
                                            return <li onClick={() => {
                                                setCity(key === city ? 0 : key);
                                            }} key={key} className={key === city ? styles.active : ''}>
                                                {item}
                                            </li>;
                                        } else {
                                            return false;
                                        }
                                    })}
                                </ul>

                                <h2>行程天数</h2>
                                <ul>
                                    {filterDays.map((item, key) => {
                                        if (key > 0) {
                                            return <li onClick={() => {
                                                setDays(key === days ? 0 : key);
                                            }} key={key} className={key === days ? styles.active : ''}>
                                                {item}
                                            </li>;
                                        } else {
                                            return false;
                                        }
                                    })}
                                </ul>

                                <h2>旅游线路</h2>
                                <ul>
                                    {filterStartCities.map((item, key) => {
                                        if (key > 0) {
                                            return <li key={key}>
                                                {item}
                                            </li>;
                                        } else {
                                            return false;
                                        }
                                    })}
                                </ul>
                                <h2>线路特色</h2>
                                <ul>
                                    {filterAttibute.map((item, key) => {
                                        if (key > 0) {
                                            return <li onClick={() => {
                                                setAttribute(key === attribute ? 0 : key);
                                            }} key={key} className={key === attribute ? styles.active : ''}>
                                                {item}
                                            </li>;
                                        } else {
                                            return false;
                                        }
                                    })}
                                </ul>
                            </div>
                            <div className={styles.filterFooter} onClick={() => {
                                setNavIndex(null);
                                search(true);
                            }}>
                                <button className={styles.filterButton}>查看线路</button>
                            </div>
                        </> : false}
                        {navIndex === 4 ? <div className={styles.sortbox}>
                            <ul>
                                {orders.map((item, key) => {
                                    return <li onClick={() => {
                                        setSort(key);
                                    }} className={key === sort ? styles.active : ''}>{item}</li>
                                })}
                            </ul>
                        </div> : false}

                    </div>
                    <div style={{ flex: 1 }} onClick={() => {
                        setNavIndex(null);
                    }}></div>
                </> : false}

            </div> : false
            }

        </div >
    } else {
        return <div ref={scroller} className={`${styles.search} ${(showModal ? '' : '')}`}></div>;
    }
}