export const page = {
    home: { url: '/wxApi/home', method: 'get' },
    search: { url: '/wxApi/search', method: 'post' },
    targets: { url: '/wxApi/targets', method: 'get' },
    target: { url: '/wxApi/target', method: 'get' },
    special: { url: '/wxApi/special', method: 'get' },
    specialSubmit: { url: '/wxApi/special/submit' },

    imageUptoken: { url: '/wxApi/qiniu/avatarUpload', method: 'get' },
    userArticleList: { url: '/wxApi/user/userArticleList' }
}